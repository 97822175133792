import axios from 'axios';
import { getAuthToken, removeAuthToken } from '../utils/authUtils.js';

const getBaseUrl = () =>
  process.env.NODE_ENV === 'development' 
    ? 'http://localhost:5000' 
    : 'https://www.vau7t.com/';

const axiosInstance = axios.create({
  baseURL: getBaseUrl(),
  timeout: 30000,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Cache-Control': 'no-cache'
  }
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = getAuthToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    // Handle authentication errors
    if (error.response?.status === 401 || error.response?.status === 403) {
      removeAuthToken();
      window.location.href = '/#/login';
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;