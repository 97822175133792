// FilePreviewComponent.js
import React from 'react';
import { Box, Typography } from '@mui/material';

const FilePreviewComponent = ({ file, size = 50 }) => {
  const fileType = file?.type || 'unknown';
  const fileName = file?.name || file?.url?.split('/').pop() || 'File';

  return (
    <Box 
      sx={{
        width: size,
        height: size,
        borderRadius: 1,
        overflow: 'hidden',
        backgroundColor: 'grey.200',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      {fileType.startsWith('image/') ? (
        <img
          src={file.previewUrl || '/api/placeholder/50/50'}
          alt={fileName}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover'
          }}
        />
      ) : (
        <Typography
          variant="caption"
          sx={{
            fontSize: '0.75rem',
            color: 'text.secondary',
            textTransform: 'uppercase'
          }}
        >
          {fileType.split('/')[1] || 'FILE'}
        </Typography>
      )}
    </Box>
  );
};

export default React.memo(FilePreviewComponent);