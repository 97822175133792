import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { Box, Typography, CircularProgress, useMediaQuery, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import { useAuth } from '../contexts/AuthContext.js';
import Header from '../components/Header.js';
import Sidebar from '../components/Sidebar.js';
import Chat from '../components/Chat.js';
import axiosInstance from '../services/api.js';
import DashboardLayout  from '../components/DashboardLayout.js';

import { useChatTitle } from '../contexts/ChatTitleContext.js';

const sidebarWidth = 270;  // Sidebar width


const Dashboard = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width: 600px)'); // Mobile responsiveness

  const { user, accessToken, isAuthenticated, loading: authLoading } = useAuth();
  const { setChatTitle } = useChatTitle();

  const [channels, setChannels] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [lastActiveChannel, setLastActiveChannel] = useState(null);
  const [lastActiveMessage, setLastActiveMessage] = useState(null);
  const [lastActiveMessages, setLastActiveMessages] = useState({});
  
  // New state for error handling
  const [fetchError, setFetchError] = useState(null);
  const [isCreateChannelDialogOpen, setIsCreateChannelDialogOpen] = useState(false);
  const [newChannelName, setNewChannelName] = useState('');


  
  const handleToggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  const [loadingState, setLoadingState] = useState(true);

  const sidebarWidth = 270;

  // Refs to store functions that might be called before initialization
  const fetchChannelMessagesRef = useRef(null);
  const fetchChannelsRef = useRef(null);

  // Helper function to fetch last active message
  const fetchLastActiveMessage = useCallback(async (channelId) => {
    try {
      const response = await axiosInstance.get(`/api/users/${user._id}/lastActiveMessage/${channelId}`);
      return response.data.lastActiveMessage || null;
    } catch (error) {
      console.error('Error fetching last active message:', error);
      return null;
    }
  }, [user?._id]);

  // Define methods in the correct order with dependencies
  const fetchChannelMessages = useCallback(async (channelId) => {
    try {
      console.log('Attempting to fetch messages for channel:', channelId);
      
      // Ensure we're not dealing with an undefined or null channelId
      if (!channelId) {
        console.warn('Invalid channel ID for message fetching');
        return;
      }
      
      const response = await axiosInstance.get(`/api/message/channel/${channelId}/messages`);
      
      // Ensure response and data exist
      if (!response?.data) {
        console.warn('Invalid response when fetching messages');
        // Update with empty array to clear previous messages
        setLastActiveMessages(prev => ({
          ...prev,
          [channelId]: []
        }));
        return;
      }
      
      const messages = response.data.messages || [];
      
      // Always update last active messages, even if empty
      setLastActiveMessages(prev => ({
        ...prev,
        [channelId]: messages
      }));
      
      console.log('✅ Messages loaded for channel:', channelId, `Total: ${messages.length}`);
    } catch (error) {
      // More comprehensive error logging
      console.error('❌ Error loading messages:', {
        channelId,
        errorMessage: error.message,
        errorResponse: error.response?.data,
        errorStatus: error.response?.status
      });
  
      // Ensure messages are cleared on error
      setLastActiveMessages(prev => ({
        ...prev,
        [channelId]: []
      }));
    }
  }, []);

  // Store the function in a ref for consistent access
  fetchChannelMessagesRef.current = fetchChannelMessages;


const handleChannelSelect = useCallback(async (channel) => {
  if (!channel?._id || !user?._id) {
    console.error("Invalid channel selection:", { channel, userId: user?._id });
    return;
  }

  try {
    setSelectedChannel(channel);
    setLastActiveChannel(channel._id);
    setChatTitle(channel.name);

    // Update last active channel in backend
    await axiosInstance.put(`/api/users/${user._id}/lastActiveChannel`, {
      lastActiveChannel: channel._id
    });

    if (isMobile) {
      setIsSidebarOpen(false);
    }

  } catch (error) {
    console.error("Channel selection error:", error);
  }
}, [user?._id, isMobile, setChatTitle]);

// In the render section:
<Chat 
  channel={selectedChannel} 
  isSidebarOpen={isSidebarOpen} 
  sidebarWidth={sidebarWidth}
  messages={[]} // Remove this prop as messages are now handled internally
  onMessageSent={() => isMobile && setIsSidebarOpen(false)}
/>




  // Fetch channels
  const fetchChannels = useCallback(async () => {
    if (!user?._id || !accessToken) return;
  
    console.log('🔄 Fetching channels and initializing data...');
  
    try {
      const response = await axiosInstance.get(`/api/channels/${user._id}`);
      
      // Comprehensive null/undefined checks
      if (!response || !response.data) {
        throw new Error('Invalid server response');
      }
  
      const { 
        channels: fetchedChannels = [], 
        lastActiveChannel: lastActiveChannelId = null 
      } = response.data;
  
      // Validate fetchedChannels is an array
      if (!Array.isArray(fetchedChannels)) {
        throw new Error('Channels data is not an array');
      }
  
      // No channels exist - open create channel dialog
      if (fetchedChannels.length === 0) {
        setIsCreateChannelDialogOpen(true);
        setChannels([]);
        return;
      }
  
      // Update channels state
      setChannels(fetchedChannels);
  
      // Channel selection logic
      let channelToSelect;
      if (lastActiveChannelId) {
        channelToSelect = fetchedChannels.find(ch => ch._id === lastActiveChannelId);
      }
  
      if (!channelToSelect && fetchedChannels.length > 0) {
        channelToSelect = fetchedChannels[0];
      }
  
      if (channelToSelect) {
        // Use a setTimeout to ensure any previous state updates are processed
        setTimeout(() => {
          handleChannelSelect(channelToSelect);
        }, 0);
      }
    } catch (error) {
      console.error("❌ Error fetching channels:", {
        errorMessage: error.message,
        responseStatus: error.response?.status,
        responseData: error.response?.data
      });
      setFetchError(error.response?.data?.message || 'Failed to fetch channels');
      setChannels([]);
    } finally {
      setLoadingState(false);
    }
  }, [user?._id, accessToken, handleChannelSelect]);

  // Store the function in a ref for consistent access
  fetchChannelsRef.current = fetchChannels;

  // Effect to fetch channels when authenticated
  useEffect(() => {
    if (!authLoading && isAuthenticated && user && accessToken) {
      fetchChannels();
    }
  }, [authLoading, isAuthenticated, user, accessToken, fetchChannels]);


  // Rest of the component remains similar to previous implementation
  return (
    <DashboardLayout
      // headerHeight={20}
      sidebarWidth={sidebarWidth}
      isSidebarOpen={isSidebarOpen}
      header={
        <Header 
          onToggleSidebar={handleToggleSidebar}
          isSidebarOpen={isSidebarOpen}
          
        />
      }
      sidebar={
        <Sidebar 
        selectedChannel={selectedChannel} 
        handleChannelSelect={handleChannelSelect} 
        channels={channels}
        setChannels={setChannels}  
        user={user}  
        sidebarWidth={sidebarWidth}
        isSidebarOpen={isSidebarOpen}
        onToggleSidebar={handleToggleSidebar}
        />
      }
      chat={
        <Chat 
          channel={selectedChannel} 
          isSidebarOpen={isSidebarOpen} 
          sidebarWidth={sidebarWidth}
          messages={lastActiveMessages[selectedChannel?._id] || []}
          onMessageSent={() => isMobile && setIsSidebarOpen(false)} // ✅ Close sidebar on mobile after sending a message

        />
      }
    />
  );
};

export default Dashboard;