import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  LinearProgress,
  Tooltip,
  IconButton,
  Collapse
} from '@mui/material';
import { 
  CheckCircle as CheckCircleIcon, 
  Error as ErrorIcon,
  Info as InfoIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon
} from '@mui/icons-material';
import { useUploadQueue } from '../contexts/UploadQueueContext.js';

const ProcessingStepIcon = ({ status }) => {
  switch (status) {
    case 'completed':
      return <CheckCircleIcon color="success" fontSize="small" />;
    case 'error':
      return <ErrorIcon color="error" fontSize="small" />;
    case 'pending':
    default:
      return <InfoIcon color="info" fontSize="small" />;
  }
};

const ProcessingIndicator = ({ message }) => {
  // Safety check for invalid message
  if (!message || typeof message !== 'object') {
    return null;
  }

  const [expandedFiles, setExpandedFiles] = useState({});
  const [updatedMessage, setUpdatedMessage] = useState(message);
  const [allFilesComplete, setAllFilesComplete] = useState(false);
  const { uploadProgress = {}, uploadLogs = {} } = useUploadQueue() || {};

  // Listen for real-time message updates
  useEffect(() => {
    if (!message._id) return;

    const handleMessageUpdate = (event) => {
      const { detail } = event;
      if (detail?.messageId === message._id) {
        setUpdatedMessage(prev => ({
          ...prev,
          fileUrls: detail.fileUrls || [],
          processingFiles: detail.processingFiles || []
        }));
      }
    };

    window.addEventListener('message-file-updated', handleMessageUpdate);
    return () => window.removeEventListener('message-file-updated', handleMessageUpdate);
  }, [message._id]);

  // Track completion status but don't hide the indicator
  useEffect(() => {
    const checkCompletion = () => {
      const filesToProcess = [
        ...(updatedMessage?.fileUrls || []).map(url => ({
          url,
          status: 'completed',
          type: 'existing'
        })),
        ...(updatedMessage?.processingFiles || [])
      ];

      const allComplete = filesToProcess.every(file => 
        file.status === 'completed' || file.url
      );

      setAllFilesComplete(allComplete);
    };

    checkCompletion();
  }, [updatedMessage]);

  // Always show files, including completed ones
  const filesToProcess = [
    ...(updatedMessage?.fileUrls || []).map(url => ({
      url,
      status: 'completed',
      type: 'existing'
    })),
    ...(updatedMessage?.processingFiles || []).map(file => ({
      ...file,
      status: 'processing',
      type: 'new'
    }))
  ];

  const toggleFileExpand = (fileName) => {
    setExpandedFiles(prev => ({
      ...prev,
      [fileName]: !prev[fileName]
    }));
  };

  return (
    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 2 }}>
      {filesToProcess.map((file, index) => {
        const fileName = file?.name || file?.url?.split('/').pop() || `File ${index + 1}`;
        const fileType = file?.type || 'unknown';
        const progress = uploadProgress[fileName] || 0;
        const isComplete = file.status === 'completed' || file.url;
        
        return (
          <Box 
            key={`${fileName}-${index}`} 
            sx={{ 
              border: '1px solid rgba(0,0,0,0.1)',
              borderRadius: 2,
              backgroundColor: isComplete ? 'rgba(0,255,0,0.03)' : 'rgba(0,0,0,0.03)',
              overflow: 'hidden'
            }}
          >
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              p: 2,
              borderBottom: '1px solid rgba(0,0,0,0.1)'
            }}>
              <Box sx={{
                width: 50,
                height: 50,
                borderRadius: 1,
                overflow: 'hidden',
                mr: 2,
                backgroundColor: 'grey.200'
              }}>
                {fileType.startsWith('image/') ? (
                  <img
                    src={file.previewUrl || '/api/placeholder/50/50'}
                    alt={fileName}
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover'
                    }}
                  />
                ) : (
                  <Box sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: '0.75rem',
                    color: 'text.secondary'
                  }}>
                    {fileType.split('/')[1]?.toUpperCase() || 'FILE'}
                  </Box>
                )}
              </Box>

              <Box sx={{ flex: 1 }}>
                <Typography variant="body2" noWrap>
                  {fileName}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  {file?.size ? `${(file.size / (1024 * 1024)).toFixed(2)} MB` : '0.00 MB'}
                </Typography>
              </Box>

              <IconButton 
                size="small"
                onClick={() => toggleFileExpand(fileName)}
              >
                {expandedFiles[fileName] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </Box>

            <Collapse in={expandedFiles[fileName]}>
              <Box sx={{ p: 2 }}>
                {['queued', 'uploading', 'processing', 'complete'].map((step, stepIndex) => {
                  const stepStatus = 
                    isComplete ? 'completed' :
                    file?.status === step ? 'pending' :
                    stepIndex < ['queued', 'uploading', 'processing', 'complete']
                      .indexOf(file?.status) ? 'completed' : 'pending';

                  return (
                    <Box key={step} sx={{ 
                      display: 'flex', 
                      alignItems: 'center', 
                      gap: 2, 
                      mb: 1 
                    }}>
                      <ProcessingStepIcon status={stepStatus} />
                      <Box sx={{ flex: 1 }}>
                        <Typography variant="body2" sx={{ 
                          textTransform: 'capitalize',
                          color: stepStatus === 'completed' ? 'success.main' : 'text.primary'
                        }}>
                          {step}
                        </Typography>
                        <Typography variant="caption" color="text.secondary" sx={{ ml: 2 }}>
                          {isComplete ? 'Completed' :
                           file?.status === step ? 'In progress...' : 
                           'Pending'}
                        </Typography>
                      </Box>
                    </Box>
                  );
                })}

                <LinearProgress 
                  variant="determinate"
                  value={isComplete ? 100 : progress}
                  sx={{ 
                    height: 6, 
                    borderRadius: 3,
                    mt: 2,
                    mb: 1,
                    '& .MuiLinearProgress-bar': {
                      backgroundColor: isComplete ? 'success.main' : 'primary.main'
                    }
                  }}
                />

                {file?.url && (
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="caption" color="text.secondary">
                      File URL:
                    </Typography>
                    <Tooltip title={file.url}>
                      <Typography 
                        variant="caption" 
                        sx={{ 
                          display: 'block',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          color: 'primary.main'
                        }}
                      >
                        {file.url}
                      </Typography>
                    </Tooltip>
                  </Box>
                )}
              </Box>
            </Collapse>
          </Box>
        );
      })}
    </Box>
  );
};

export default ProcessingIndicator;