import React, { createContext, useState, useCallback, useRef } from 'react';
import axiosInstance from '../services/api.js';
import UploadQueue from './components/UploadQueue.js';
import { formatBytes } from '../utils/uploadUtils.js';

const UploadQueueContext = createContext();

const CHUNK_SIZE = 4 * 1024 * 1024; // 4MB chunks
const MAX_RETRIES = 5;
const MAX_BACKOFF = 30000; // 30 seconds

function useUploadQueue() {
  const context = React.useContext(UploadQueueContext);
  if (!context) {
    throw new Error('useUploadQueue must be used within an UploadQueueProvider');
  }
  return context;
}

function UploadQueueProvider({ children, onMessageUpdated  }) {
  // States
  const [uploadQueue, setUploadQueue] = useState([]);
  const [uploadProgress, setUploadProgress] = useState({});
  const [uploadErrors, setUploadErrors] = useState({});
  const [uploadLogs, setUploadLogs] = useState({});
  const [minimized, setMinimized] = useState(true);
  const [processingFiles, setProcessingFiles] = useState(new Set());

  // Refs
  const uploadControllersRef = useRef(new Map());
  const processingStatusRef = useRef(new Map());
  const messageUrlMapRef = useRef(new Map());
  const fileAssociationsRef = useRef(new Map()); // Track file -> message associations
// Add a new ref to track recently sent files
const recentlySentFilesRef = useRef(new Map()); // fileName -> {messageId, timestamp}


// Enhanced file status tracking
// Enhanced file status tracking
const isFileProcessing = useCallback((fileName) => {
  return (
    processingFiles.has(fileName) ||
    uploadControllersRef.current.has(fileName) ||
    fileAssociationsRef.current.has(fileName)
  );
}, [processingFiles]);


  // Add a function to check if a file is already being processed
  const isFileInQueue = useCallback((fileName, fileSize, lastModified) => {
    return uploadQueue.some(item => 
      item.file.name === fileName &&
      item.file.size === fileSize &&
      item.file.lastModified === lastModified
    );
  }, [uploadQueue]);



// Enhanced queue addition with better duplicate checking
const queueFiles = useCallback((files, channelId) => {
  const newFiles = files.filter(file => {
    if (isFileProcessing(file.name)) {
      setUploadErrors(prev => ({
        ...prev,
        [file.name]: 'File already being processed'
      }));
      return false;
    }
    return true;
  });

  if (newFiles.length > 0) {
    setUploadQueue(prev => [
      ...prev,
      ...newFiles.map(file => ({
        file,
        channelId,
        status: 'queued',
        addedAt: Date.now()
      }))
    ]);
  }
}, [isFileProcessing]);


// Add message tracking to UploadQueueProvider
const messageQueueMapRef = useRef(new Map()); // Track which files belong to which message


// Add processing queue
const uploadQueueProcessor = useRef({
  isProcessing: false,
  queue: new Map(), // messageId -> files
  activeUploads: new Set(),
  fileToMessage: new Map() // fileName -> messageId mapping
});



  // 1. Base utility functions
  const calculateBackoff = useCallback((retryCount) => 
    Math.min(1000 * Math.pow(2, retryCount), MAX_BACKOFF)
  , []);

  const getStatusMessage = useCallback((status, details) => {
    switch (status) {
      case 'uploading':
        return `📤 Uploading chunk ${details.currentChunk}/${details.totalChunks}`;
      case 'processing':
        return '🔄 Processing file...';
      case 'stitching':
        return '🔄 Combining file chunks...';
      case 'complete':
        return '✅ Upload complete!';
      case 'error':
        return `❌ ${details.error || 'Upload failed'}`;
      default:
        return `ℹ️ Status: ${status}`;
    }
  }, []);

  // 2. Basic state updates
  const updateProcessingStatus = useCallback((fileName, status, details = {}) => {
    processingStatusRef.current.set(fileName, {
      status,
      timestamp: Date.now(),
      ...details
    });

    setUploadLogs(prev => {
      const currentLogs = prev[fileName] || [];
      const statusMessage = getStatusMessage(status, details);
      return {
        ...prev,
        [fileName]: [...currentLogs, statusMessage]
      };
    });
  }, [getStatusMessage]);

// Improved file removal with better cleanup
const removeFileFromQueue = useCallback((fileName, messageId) => {
  console.log(`🗑️ Removing file from queue: ${fileName}`);
  
  const processor = uploadQueueProcessor.current;
  
  // Check if file is currently uploading and cancel it
  const controller = uploadControllersRef.current.get(fileName);
  if (controller) {
    console.log(`⏹️ Aborting upload for: ${fileName}`);
    controller.abort();
    uploadControllersRef.current.delete(fileName);
  }

  // Remove file from all tracking maps
  processor.activeUploads.delete(fileName);
  processor.fileToMessage.delete(fileName);
  fileAssociationsRef.current.delete(fileName);
  messageQueueMapRef.current.delete(fileName);
    recentlySentFilesRef.current.delete(fileName); // Add this line


  // Remove file from processing queue for any message
  for (const [msgId, files] of processor.queue.entries()) {
    const updatedFiles = files.filter(f => f.name !== fileName);
    if (updatedFiles.length === 0) {
      processor.queue.delete(msgId);
    } else {
      processor.queue.set(msgId, updatedFiles);
    }
  }

  // Clear from states
  setUploadQueue(prev => prev.filter(item => item.file.name !== fileName));
  setUploadProgress(prev => {
    const { [fileName]: _, ...rest } = prev;
    return rest;
  });
  setUploadErrors(prev => {
    const { [fileName]: _, ...rest } = prev;
    return rest;
  });
  setUploadLogs(prev => {
    const { [fileName]: _, ...rest } = prev;
    return rest;
  });
  
  setProcessingFiles(prev => {
    const newSet = new Set(prev);
    newSet.delete(fileName);
    return newSet;
  });

  console.log(`✅ Successfully removed ${fileName} from queue`);
}, []);

  // 4. File processing functions
  const processFile = useCallback(async (fileName, totalChunks, channelId, messageId) => {
    try {
      updateProcessingStatus(fileName, 'stitching');
      
      // Use the correct endpoint for processing files
      const response = await axiosInstance.post('/api/files/complete-upload', {
        fileName,
        totalChunks,
        channelId,
        messageId
      });
  
      if (!response.data) {
        throw new Error('No response from processing endpoint');
      }
  
      // Update status based on response
      if (response.data.success) {
        updateProcessingStatus(fileName, 'complete');
        
        return {
          success: true,
          fileUrl: response.data.fileUrls, // Note: server returns fileUrls not fileUrl
          fileName: fileName
        };
      } else {
        throw new Error(response.data.message || 'File processing failed');
      }
  
    } catch (error) {
      console.error(`❌ Processing failed for ${fileName}:`, error);
      
      // Handle specific error cases
      let errorMessage = error.message;
      if (error.response?.status === 404) {
        errorMessage = 'File processing endpoint not found. Please check server configuration.';
      } else if (error.response?.status === 500) {
        errorMessage = 'Server error while processing file.';
      }
      
      updateProcessingStatus(fileName, 'error', { error: errorMessage });
      throw error;
    }
  }, [updateProcessingStatus]);

  
  // 5. Upload functions
  const uploadChunk = useCallback(async (chunk, file, index, totalChunks, channelId) => {
    let retryCount = 0;
    
    while (retryCount < MAX_RETRIES) {
      try {
        // Create a blob from the chunk
        const chunkBlob = new Blob([chunk], { type: file.type });
        
        const formData = new FormData();
        formData.append('chunk', chunkBlob, file.name);
        formData.append('chunkIndex', index.toString());
        formData.append('totalChunks', totalChunks.toString());
        formData.append('fileName', file.name);
        formData.append('channelId', channelId);
        formData.append('fileSize', chunkBlob.size.toString());
        formData.append('mimeType', file.type);
  
        const controller = uploadControllersRef.current.get(file.name);
        
        const response = await axiosInstance.post('/api/files/upload-chunk', formData, {
          signal: controller?.signal,
          timeout: 60000, // 60 second timeout
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: (e) => {
            const overallProgress = ((index * CHUNK_SIZE + e.loaded) / file.size) * 100;
            setUploadProgress(prev => ({
              ...prev,
              [file.name]: Math.min(Math.round(overallProgress), 99)
            }));
          }
        });
  
        if (response.data?.success) {
          console.log(`✅ Chunk ${index + 1}/${totalChunks} uploaded`);
          return true;
        }
        throw new Error(response.data?.message || 'Chunk upload failed');
  
      } catch (error) {
        if (error.code === 'ECONNABORTED' || error.response?.status === 504) {
          retryCount++;
          if (retryCount === MAX_RETRIES) {
            throw new Error(`Chunk upload failed after ${MAX_RETRIES} retries`);
          }
          console.log(`Retrying chunk ${index} (Attempt ${retryCount}/${MAX_RETRIES})`);
          await new Promise(resolve => setTimeout(resolve, calculateBackoff(retryCount)));
          continue;
        }
        throw error;
      }
    }
    return false;
  }, [calculateBackoff]);

  const uploadFile = useCallback(async (file, channelId, messageId) => {
    const controller = new AbortController();
    
    try {
      // Check for existing upload or message association
      if (uploadControllersRef.current.has(file.name)) {
        const existingMessageId = fileAssociationsRef.current.get(file.name);
        if (existingMessageId && existingMessageId !== messageId) {
          console.log(`⚠️ File ${file.name} already being uploaded for message ${existingMessageId}`);
          return { status: 'already_uploading', messageId: existingMessageId };
        }
      }
  
      // Check if file is already processed
      if (processingFiles.has(file.name)) {
        console.log(`⚠️ File ${file.name} is already being processed`);
        return { status: 'processing' };
      }
      
      // Set up file tracking
      uploadControllersRef.current.set(file.name, controller);
      fileAssociationsRef.current.set(file.name, messageId);
      setProcessingFiles(prev => new Set(prev).add(file.name));
      
      const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
      
      // Update initial status
      updateProcessingStatus(file.name, 'starting', {
        totalSize: formatBytes(file.size),
        totalChunks,
        messageId
      });
  
      // Track uploaded chunks
      const uploadedChunks = new Set();
      
      // Upload all chunks
      for (let index = 0; index < totalChunks; index++) {
        // Check for cancellation before each chunk
        if (!uploadControllersRef.current.has(file.name)) {
          console.log(`⚠️ Upload cancelled for ${file.name}`);
          throw new Error('Upload cancelled by user');
        }
  
        const start = index * CHUNK_SIZE;
        const end = Math.min(start + CHUNK_SIZE, file.size);
        const chunk = file.slice(start, end);
  
        // Update chunk progress status
        updateProcessingStatus(file.name, 'uploading', {
          currentChunk: index + 1,
          totalChunks,
          uploadedChunks: uploadedChunks.size,
          messageId
        });
  
        // Upload chunk with retry logic
        const success = await uploadChunk(chunk, file, index, totalChunks, channelId);
        
        if (success) {
          uploadedChunks.add(index);
          
          // Update progress for UI
          const progress = Math.round((uploadedChunks.size / totalChunks) * 100);
          setUploadProgress(prev => ({
            ...prev,
            [file.name]: Math.min(progress, 99) // Cap at 99% until fully complete
          }));
        } else {
          throw new Error('Chunk upload failed');
        }
      }
  
      // Update final progress
      setUploadProgress(prev => ({
        ...prev,
        [file.name]: 100
      }));
  
      // Return successful completion status
      return {
        status: 'complete',
        fileName: file.name,
        totalChunks,
        uploadedChunks: uploadedChunks.size,
        messageId
      };
  
    } catch (error) {
      // Handle errors appropriately
      if (error.message !== 'Upload cancelled by user') {
        updateProcessingStatus(file.name, 'error', { 
          error: error.message || 'Upload failed',
          messageId 
        });
        
        setUploadErrors(prev => ({
          ...prev,
          [file.name]: error.message || 'Upload failed'
        }));
      }
      
      // Clear progress on error
      setUploadProgress(prev => {
        const { [file.name]: _, ...rest } = prev;
        return rest;
      });
  
      throw error;
    } finally {
      // Clean up resources
      uploadControllersRef.current.delete(file.name);
      setProcessingFiles(prev => {
        const newSet = new Set(prev);
        newSet.delete(file.name);
        return newSet;
      });
      
      // Only remove file association if this is the associated message
      if (fileAssociationsRef.current.get(file.name) === messageId) {
        fileAssociationsRef.current.delete(file.name);
      }
    }
  }, [uploadChunk, updateProcessingStatus, formatBytes, processingFiles]);



  const uploadFiles = useCallback(async (messageId, channelId) => {
    const processor = uploadQueueProcessor.current;
    const results = new Map();
    const errors = new Map();
    const completedFiles = new Set();
  
    // Add files to processing queue
    const newFiles = uploadQueue.filter(({ file }) => {
      // Check if file is already being processed or assigned to a message
      if (processor.activeUploads.has(file.name) || processor.fileToMessage.has(file.name)) {
        console.log(`File ${file.name} is already being processed or assigned to a message`);
        return false;
      }
  
      // Add file to message mapping
      processor.fileToMessage.set(file.name, messageId);
      return true;
    });
  
    if (newFiles.length > 0) {
      processor.queue.set(messageId, newFiles.map(({ file }) => file));
      setMinimized(true);
      
      const processFiles = async () => {
        if (processor.isProcessing) return;
        processor.isProcessing = true;
  
        try {
          // Process each file sequentially
          for (const [msgId, files] of processor.queue) {
            for (const file of files) {
              // Skip if file has been removed
              if (!processor.fileToMessage.has(file.name)) continue;
  
              try {
                processor.activeUploads.add(file.name);
                const uploadStatus = await uploadFile(file, channelId, msgId);
                
                if (uploadStatus.status === 'complete') {
                  const result = await processFile(file.name, uploadStatus.totalChunks, channelId, msgId);
                  
                  if (result.success) {
                    // Update message with new file URL
                    const currentUrls = messageUrlMapRef.current.get(msgId) || [];
                    const updatedUrls = [...currentUrls, result.fileUrl];
                    
                    await axiosInstance.post(
                      `/api/message/channel/${channelId}/message/${msgId}/update`,
                      {
                        fileUrls: updatedUrls,
                        processingFiles: files
                          .filter(f => !completedFiles.has(f.name))
                          .map(f => ({
                            name: f.name,
                            size: f.size,
                            type: f.type
                          }))
                      }
                    );
  
                    // Update tracking
                    completedFiles.add(file.name);
                    messageUrlMapRef.current.set(msgId, updatedUrls);
                    processor.activeUploads.delete(file.name);
                    processor.fileToMessage.delete(file.name);
  
                    // Notify UI
                    window.dispatchEvent(new CustomEvent('message-file-updated', {
                      detail: {
                        messageId: msgId,
                        fileUrls: updatedUrls,
                        processingFiles: files.filter(f => !completedFiles.has(f.name))
                      }
                    }));
  
                    if (typeof onMessageUpdated === 'function') {
                      onMessageUpdated(msgId, updatedUrls);
                    }
  
                    // Remove file from queue after delay
                    setTimeout(() => removeFileFromQueue(file.name), 2000);
                  }
                }
              } catch (error) {
                console.error(`Error processing file ${file.name}:`, error);
                errors.set(file.name, error.message || 'Unknown error');
                processor.activeUploads.delete(file.name);
                processor.fileToMessage.delete(file.name);
              }
            }
            // Remove message from queue when all its files are done
            const remainingFiles = files.filter(f => !completedFiles.has(f.name));
            if (remainingFiles.length === 0) {
              processor.queue.delete(msgId);
            }
          }
        } finally {
          processor.isProcessing = false;
          
          // Process any remaining files
          if (processor.queue.size > 0) {
            processFiles();
          }
        }
      };
  
      // Start processing
      processFiles();
    }
  
    return {
      success: true,
      uploadStarted: true,
      totalFiles: newFiles.length
    };
  }, [uploadQueue, uploadFile, processFile, removeFileFromQueue, onMessageUpdated]);
  
  
// Enhanced message sending with better duplicate prevention
const sendMessageWithFiles = useCallback(async (messageContent, channelId) => {
  try {
    const processor = uploadQueueProcessor.current;
    const currentTime = Date.now();
    
    // Clean up old entries from recentlySentFiles
    for (const [fileName, data] of recentlySentFilesRef.current.entries()) {
      if (currentTime - data.timestamp > 5000) {
        recentlySentFilesRef.current.delete(fileName);
      }
    }

    // Check all possible file states
    const hasActiveUploads = processor.activeUploads.size > 0;
    const hasAssignedFiles = processor.fileToMessage.size > 0;
    const hasRecentlySentFiles = recentlySentFilesRef.current.size > 0;
    const hasFilesInProgress = hasActiveUploads || hasAssignedFiles || hasRecentlySentFiles;

    // Get valid new files that aren't being processed
    const newFiles = uploadQueue.filter(({ file }) => {
      return !processor.activeUploads.has(file.name) && 
             !processor.fileToMessage.has(file.name) &&
             !recentlySentFilesRef.current.has(file.name);
    });

    // Handle various scenarios
    const hasValidContent = messageContent?.trim();
    const hasNewFiles = newFiles.length > 0;

    // Case 1: Files are uploading and no text content - block the message
    if (hasFilesInProgress && !hasValidContent) {
      return {
        success: false,
        message: 'Please wait for current uploads to complete before sending another message'
      };
    }

    // Case 2: No content and no files - block the message
    if (!hasValidContent && !hasNewFiles) {
      return {
        success: false,
        message: 'No content to send'
      };
    }

    // Case 3: Only text content (with or without files uploading) - send text only
    if (hasValidContent && !hasNewFiles) {
      const messageResponse = await axiosInstance.post(
        `/api/message/channel/${channelId}/send`,
        { text: messageContent }
      );

      if (!messageResponse.data.success) {
        throw new Error('Failed to create message');
      }

      return {
        success: true,
        messageId: messageResponse.data.message._id,
        initialFiles: []
      };
    }

    // Case 4: New files (with or without text) - handle normally
    const messageData = {
      text: messageContent || '',
      fileUrls: [],
      processingFiles: newFiles.map(({ file }) => ({
        name: file.name,
        size: file.size,
        type: file.type,
        previewUrl: file.type.startsWith('image/') ? URL.createObjectURL(file) : null
      }))
    };

    const messageResponse = await axiosInstance.post(
      `/api/message/channel/${channelId}/send`, 
      messageData
    );

    if (!messageResponse.data.success) {
      throw new Error('Failed to create message');
    }

    const messageId = messageResponse.data.message._id;

    // Track and start upload for new files
    newFiles.forEach(({ file }) => {
      recentlySentFilesRef.current.set(file.name, {
        messageId,
        timestamp: currentTime
      });
    });

    if (newFiles.length > 0) {
      uploadFiles(messageId, channelId);
    }

    return {
      success: true,
      messageId,
      initialFiles: messageData.processingFiles
    };

  } catch (error) {
    console.error('Failed to send message:', error);
    return { success: false, error: error.message };
  }
}, [uploadQueue, uploadFiles]);



  // 6. Helper functions
  const updateMessageWithFile = useCallback(async (messageId, channelId, fileUrl) => {
    // ... updateMessageWithFile implementation
  }, []



);

  return (
    <UploadQueueContext.Provider
      value={{
        isFileProcessing,
        uploadQueue,
        uploadProgress,
        uploadErrors,
        uploadLogs,
        minimized,
        setMinimized,
        isProcessing: processingFiles.size > 0,
        processingFiles,
        addFilesToQueue: queueFiles,
        removeFileFromQueue,
        uploadFiles,
        sendMessageWithFiles, // Added this
        updateMessageWithFile,
        getProcessingStatus: () => ({
          isProcessing: processingFiles.size > 0,
          processingCount: processingFiles.size,
          processingFiles: Array.from(processingFiles)
        }),
        getMessageUrls: messageId => 
          messageUrlMapRef.current.get(messageId) || []
      }}
    >
      <UploadQueue />
      {children}
    </UploadQueueContext.Provider>
  );
}

export { useUploadQueue };
export default UploadQueueProvider;