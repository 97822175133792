import React, { useState, useCallback } from 'react';
import { Box, Typography, Tooltip } from '@mui/material';
import { 
  InsertDriveFile, 
  ImageOutlined, 
  VideoLibrary, 
  PictureAsPdf, 
  FilePresent, 
  BrokenImage 
} from '@mui/icons-material';

const FilePreviewItem = ({ 
  fileUrl, 
  isSingleFile = false, 
  isMobile = false,
  onPreviewClick 
}) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const getWrappedUrl = (url) => url && !url.includes('?wrap=0') ? `${url}?wrap=0` : url;
  const wrappedUrl = getWrappedUrl(fileUrl);

  const fileName = fileUrl ? decodeURIComponent(fileUrl.split('/').pop()) : 'Unknown File';
  const fileExt = fileName.split('.').pop().toLowerCase();

  const fileTypes = {
    image: ['jpg', 'jpeg', 'png', 'gif', 'webp', 'bmp', 'svg'],
    video: ['mp4', 'webm', 'mov', 'avi', 'mkv'],
    pdf: ['pdf'],
    document: ['doc', 'docx', 'txt', 'rtf']
  };

  const getFileType = useCallback(() => {
    if (fileTypes.image.includes(fileExt)) return 'image';
    if (fileTypes.video.includes(fileExt)) return 'video';
    if (fileTypes.pdf.includes(fileExt)) return 'pdf';
    if (fileTypes.document.includes(fileExt)) return 'document';
    return 'unknown';
  }, [fileExt]);

  const fileType = getFileType();

  const commonStyles = {
    width: '100%',
    height: '100%',
    borderRadius: '5px',
    backgroundColor: '#f9f9f9',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    position: 'relative',
    cursor: 'pointer'
  };

  return (
    <Tooltip title={fileName} arrow>
      <Box onClick={() => onPreviewClick?.(wrappedUrl)} sx={commonStyles}>
        {fileType === 'image' && (
          <img
            src={wrappedUrl}
            alt={fileName}
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            onLoad={() => setLoading(false)}
            onError={() => setError(true)}
          />
        )}

        {fileType === 'video' && (
          <video
            src={wrappedUrl}
            controls
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            onError={() => setError(true)}
          >
            Your browser does not support the video tag.
          </video>
        )}

        {fileType === 'pdf' && (
          <PictureAsPdf sx={{ fontSize: 50, color: 'error.main' }} />
        )}

        {fileType === 'document' && (
          <InsertDriveFile sx={{ fontSize: 50, color: 'info.main' }} />
        )}

        {fileType === 'unknown' && (
          <FilePresent sx={{ fontSize: 50, color: 'text.secondary' }} />
        )}

        {error && (
          <BrokenImage sx={{ fontSize: 50, color: 'error.main' }} />
        )}

        <Typography 
          variant="caption" 
          sx={{ 
            position: 'absolute', 
            bottom: 4, 
            left: 4, 
            right: 4, 
            textAlign: 'center', 
            fontSize: '0.7rem', 
            backgroundColor: 'rgba(0, 0, 0, 0.6)', 
            color: 'white', 
            padding: '2px 4px', 
            borderRadius: '3px', 
            wordWrap: 'break-word', 
            overflowWrap: 'break-word', 
            maxWidth: 'calc(100% - 8px)',
            whiteSpace: 'normal',
            lineHeight: 1.2
          }}
        >
          {fileName}
        </Typography>
      </Box>
    </Tooltip>
  );
};

export default React.memo(FilePreviewItem);