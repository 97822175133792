import React from 'react';
import { Box, Fade } from '@mui/material';
import { useUploadQueue } from '../contexts/UploadQueueContext.js';
import FilePreviewItem from './FilePreviewItem.js';
import MessageFilePreview from './MessageFilePreview.js';

const MessageFileList = ({ message, isMobile, handleFilePreview, styles }) => {
  const { uploadQueue, removeFileFromQueue } = useUploadQueue();
  const fileUrls = message.fileUrls || [];
  const processingFiles = message.processingFiles || [];
  
  // Filter processingFiles to only show files that are still in the upload queue
  const activeProcessingFiles = React.useMemo(() => 
    processingFiles.filter(file => 
      uploadQueue.some(queueItem => queueItem.file.name === file.name)
    ),
    [processingFiles, uploadQueue]
  );
  
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: isMobile ? 
          'repeat(auto-fill, minmax(120px, 1fr))' : 
          'repeat(auto-fill, minmax(180px, 1fr))',
        gap: 1,
        mt: 2
      }}
    >
      {/* Show completed files */}
      {fileUrls.map((fileUrl, index) => (
        <Fade
          key={`completed-${index}`}
          in
          timeout={300}
          style={{ transitionDelay: `${index * 50}ms` }}
        >
          <Box>
            <FilePreviewItem
              fileUrl={fileUrl}
              isSingleFile={fileUrls.length === 1}
              isMobile={isMobile}
              onPreviewClick={() => handleFilePreview(fileUrls, index)}
            />
          </Box>
        </Fade>
      ))}

      {/* Show processing files that are still in queue */}
      {activeProcessingFiles.map((file, index) => (
        <Fade
          key={`processing-${file.name}-${index}`}
          in
          timeout={300}
          style={{ transitionDelay: `${(fileUrls.length + index) * 50}ms` }}
        >
          <Box>
            <MessageFilePreview
              file={file}
              isProcessing={true}
              isMobile={isMobile}
              onRemove={() => removeFileFromQueue(file.name, message._id)}
            />
          </Box>
        </Fade>
      ))}
    </Box>
  );
};

export default React.memo(MessageFileList);
