import React, { useState, useCallback, useRef } from 'react';
import {
  Dialog,
  DialogContent,
  IconButton,
  Box,
  Typography,
  useTheme,
  CircularProgress
} from '@mui/material';
import {
  Close,
  NavigateBefore,
  NavigateNext,
  FileDownload,
  Error
} from '@mui/icons-material';

const FilePreviewModal = ({
  open,
  onClose,
  files,
  currentIndex = 0,
  onNext,
  onPrev,
  isFullscreen,
  isMobile
}) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const touchStartXRef = useRef(0);
  const touchStartYRef = useRef(0);
  const [isDragging, setIsDragging] = useState(false);
  const [dragOffset, setDragOffset] = useState(0);

  const SWIPE_THRESHOLD = 50;  // Minimum swipe distance for actions
  const CLOSE_THRESHOLD = 100; // Pull down distance to close

  const getWrappedUrl = useCallback((url) => {
    return url && !url.includes('?wrap=0') ? `${url}?wrap=0` : url;
  }, []);

  const currentFile = getWrappedUrl(files?.[currentIndex]);
  const fileName = currentFile ? decodeURIComponent(currentFile.split('/').pop().split('?')[0]) : '';
  const fileExt = fileName.split('.').pop().toLowerCase();

  const isImage = /\.(jpg|jpeg|png|gif|webp|bmp)$/i.test(fileName);
  const isVideo = /\.(mp4|webm|mov|avi|mkv|quicktime)$/i.test(fileName);
  const isPDF = /\.pdf$/i.test(fileName);

  const handleClose = () => {
    setLoading(true);
    setError(false);
    onClose();
  };

  const handleLoad = () => {
    setLoading(false);
    setError(false);
  };

  const handleError = () => {
    setLoading(false);
    setError(true);
  };

  // 🟢 Touch Handlers for Mobile Gestures
  const handleTouchStart = (e) => {
    if (!isMobile) return;
    touchStartXRef.current = e.touches[0].clientX;
    touchStartYRef.current = e.touches[0].clientY;
    setIsDragging(true);
    setDragOffset(0);
  };

  const handleTouchMove = (e) => {
    if (!isDragging) return;

    const touchX = e.touches[0].clientX;
    const touchY = e.touches[0].clientY;

    const deltaX = touchX - touchStartXRef.current;
    const deltaY = touchY - touchStartYRef.current;

    if (Math.abs(deltaX) > Math.abs(deltaY)) {
      // Horizontal Swipe (Next/Prev)
      setDragOffset(deltaX);
    } else {
      // Vertical Swipe (Close)
      setDragOffset(deltaY);
    }
  };

  const handleTouchEnd = () => {
    if (!isDragging) return;

    // Horizontal Swipe (Next/Prev)
    if (Math.abs(dragOffset) > SWIPE_THRESHOLD) {
      if (dragOffset < 0) {
        onNext(); // Swipe Left → Next
      } else {
        onPrev(); // Swipe Right → Previous
      }
    }

    // Vertical Swipe (Pull Down to Close)
    if (dragOffset > CLOSE_THRESHOLD) {
      handleClose();
    }

    setIsDragging(false);
    setDragOffset(0);
  };

  const renderPreview = () => {
    if (!currentFile) return null;

    const dragStyle = isDragging
      ? {
          transform: `translate(${dragOffset}px, ${dragOffset}px)`,
          transition: 'none'
        }
      : {
          transform: 'translate(0, 0)',
          transition: 'transform 0.3s ease-out'
        };

    return (
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          touchAction: isMobile ? 'none' : 'auto',
          ...dragStyle
        }}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        {loading && (
          <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
            <CircularProgress />
          </Box>
        )}

        {error ? (
          <Box sx={{ textAlign: 'center' }}>
            <Error sx={{ fontSize: 48, color: 'error.main', mb: 2 }} />
            <Typography color="error">Failed to load file</Typography>
          </Box>
        ) : isImage ? (
          <img
            src={currentFile}
            alt={fileName}
            onLoad={handleLoad}
            onError={handleError}
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
              objectFit: 'contain',
              display: loading ? 'none' : 'block'
            }}
          />
        ) : isVideo ? (
          <video
            src={currentFile}
            controls
            style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
            onLoadedData={handleLoad}
            onError={handleError}
          />
        ) : isPDF ? (
          <iframe
            src={currentFile}
            title={fileName}
            width="100%"
            height="100%"
            onLoad={handleLoad}
            onError={handleError}
            style={{ border: 'none' }}
          />
        ) : (
          <Box sx={{ textAlign: 'center' }}>
            <Typography>
              This file type cannot be previewed.
              <br />
              Click download to view the file.
            </Typography>
          </Box>
        )}
      </Box>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={false}
      fullScreen={isMobile || isFullscreen}
      PaperProps={{
        sx: {
          width: isMobile || isFullscreen ? '100%' : '90vw',
          height: isMobile || isFullscreen ? '100%' : '90vh',
          backgroundColor: theme.palette.grey[900],
          overflow: 'hidden'
        }
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          p: 1,
          bgcolor: 'rgba(0,0,0,0.7)',
          zIndex: 1
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            color: 'white',
            px: 2,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            maxWidth: '70%'
          }}
        >
          {fileName}
        </Typography>
        <Box>
          <IconButton onClick={() => window.open(currentFile, '_blank')} sx={{ color: 'white' }}>
            <FileDownload />
          </IconButton>
          <IconButton onClick={handleClose} sx={{ color: 'white' }}>
            <Close />
          </IconButton>
        </Box>
      </Box>

      <DialogContent
        sx={{
          p: 0,
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          bgcolor: theme.palette.grey[900],
          overflow: 'hidden'
        }}
      >
        {renderPreview()}
      </DialogContent>
    </Dialog>
  );
};

export default React.memo(FilePreviewModal);
