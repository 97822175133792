import React, { useState, useCallback } from 'react';
import { Box, IconButton, Drawer, List, ListItem, ListItemText, Typography, Button } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { NavLink } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext.js';
import axiosInstance from '../services/api.js';
import { useChatTitle } from '../contexts/ChatTitleContext.js';
import SessionMonitor from '../components/SessionMonitor.js';

const Header = ({ onToggleSidebar, isSidebarOpen }) => {
  const [showGif, setShowGif] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { user, logout } = useAuth();
  const { chatTitle } = useChatTitle();

  const handleLogoClick = () => {
    setShowGif(true);
    onToggleSidebar();
    setTimeout(() => setShowGif(false), 1500);
  };

  const toggleDrawer = (open) => () => {
    setIsDrawerOpen(open);
  };

  const handleLogout = useCallback(async (e) => {
    e.preventDefault();
    try {
      if (isDrawerOpen) {
        setIsDrawerOpen(false);
      }
      await axiosInstance.post('/api/auth/logout', {}, { withCredentials: true });
      await logout();
      console.log('✅ Logout successful');
    } catch (error) {
      console.error('❌ Logout failed:', error.response?.data || error.message);
    }
  }, [logout, isDrawerOpen]);

  const navItems = user ? (
    <>
            <Button color="inherit" fullWidth sx={{ textTransform: 'none', color:'black' }}>   <SessionMonitor variant="minimal" showUserDetails={true} />
            </Button>

      <NavLink to="/dashboard" style={{ textDecoration: 'none' }} onClick={() => setIsDrawerOpen(false)}>
        <Button color="inherit" fullWidth sx={{ textTransform: 'none' }}>Dashboard</Button>
      </NavLink>

      <NavLink to="/traffic" style={{ textDecoration: 'none' }} onClick={() => setIsDrawerOpen(false)}>
        <Button color="inherit" fullWidth sx={{ textTransform: 'none' }}>Traffic</Button>
      </NavLink>


      <NavLink to="/TokenTester" style={{ textDecoration: 'none' }} onClick={() => setIsDrawerOpen(false)}>
        <Button color="inherit" fullWidth sx={{ textTransform: 'none' }}>TokenTester</Button>
      </NavLink>
      <NavLink to="/AuthDashboard" style={{ textDecoration: 'none' }} onClick={() => setIsDrawerOpen(false)}>
        <Button color="inherit" fullWidth sx={{ textTransform: 'none' }}>AuthDashboard</Button>
      </NavLink>
      <Button color="inherit" onClick={handleLogout} fullWidth sx={{ textTransform: 'none', color: 'black'  }}>
        Logout
      </Button>
    </>
  ) : (
    <NavLink to="/login" style={{ textDecoration: 'none' }} onClick={() => setIsDrawerOpen(false)}>
      <Button color="inherit" fullWidth sx={{ textTransform: 'none' }}>Login</Button>
    </NavLink>
  );

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        // backgroundColor: 'orange',
        color: 'white',
        padding: '10px 20px',
      }}
    >
      {/* Logo and Chat Title Wrapper */}
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        {/* Logo triggers the sidebar toggle */}
        <Box
          onClick={handleLogoClick}
          sx={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            transition: 'transform 0.3s ease-in-out',
            transform: isSidebarOpen ? 'rotate(0deg)' : 'rotate(180deg)',
            '&:hover': { transform: 'scale(1.05)' },
          }}
        >
          <img
            src={showGif ? '/images/logoGif.gif' : '/images/logo.png'}
            alt="Logo"
            style={{ width: 40 }}
          />
        </Box>

        {/* Display the selected channel name */}
        {chatTitle && (
  <Box 
    sx={{ 
      display: 'flex', 
      alignItems: 'center', 
      // backgroundColor: '', 
      borderRadius: '4px', 
      padding: '4px 8px',
      cursor: 'pointer',               // Add pointer cursor
      '&:hover': { backgroundColor: '#bbdefb' } // Hover effect for better UX
    }} 
    onClick={onToggleSidebar}          // Trigger sidebar toggle on click
  >
    <Typography
      variant="h6"
      sx={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        maxWidth: '200px',
        color: 'black',
        fontWeight: 500,
      }}
    >
      {`: ${chatTitle}`}
    </Typography>
  </Box>
)}

      </Box>

      {/* SessionMonitor & MenuIcon Wrapper */}
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, color: 'black'  }}>
        {/* <SessionMonitor variant="minimal" showUserDetails={true} /> */}
        <IconButton onClick={toggleDrawer(true)} sx={{ color: 'black' }}>
          <MenuIcon />
        </IconButton>
      </Box>

      {/* Drawer for Mobile Menu */}
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={toggleDrawer(false)}
        sx={{
          '& .MuiDrawer-paper': {
            width: 200,
            // backgroundColor: '#f5f5f5',
            color: '#fff',            // Ensuring text is visible with white color

          },
        }}
      >
        <Box
          sx={{
            padding: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <List sx={{ width: '100%' }}>
            {navItems}
          </List>
        </Box>
      </Drawer>
    </Box>
  );
};

export default Header;
