import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Paper, Typography, Box, Divider, Button, Snackbar, Alert, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, CircularProgress, IconButton } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useAuth } from '../contexts/AuthContext.js';
import axiosInstance from '../services/api.js';
import { handleCookies, detectPlatform, getCookie } from '../utils/cookieUtils.js'; // Import getCookie
import CookieVerification from '../components/CookieVerification.js';
import Header from '../components/Header.js';


const formatResponse = (logEntry) => {
    try {
        const parsed = typeof logEntry === 'string' ? JSON.parse(logEntry) : logEntry;
        return JSON.stringify(parsed, null, 2);
    } catch (e) {
        return logEntry;
    }
};

const formatTimeRemaining = (milliseconds) => {
    let seconds = Math.floor(milliseconds / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    let days = Math.floor(hours / 24);

    seconds = seconds % 60;
    minutes = minutes % 60;
    hours = hours % 24;

    return `${days}d ${hours}h ${minutes}m ${seconds}s`;
};

const TokenTester = () => {
    const { 
        user, 
        accessToken, 
        isAuthenticated, 
        isLoading: authLoading, 
        accessTokenExpiration: contextTokenExpiration, 
        refreshTokenExpiration: contextRefreshExpiration, 
        refreshTokens: contextRefreshTokens, 
        updateSession, // Add this from your AuthContext
        error: authError 
    } = useAuth();

    const [logs, setLogs] = useState([]);
    const [requestHeaders, setRequestHeaders] = useState(null);
    const [cookies, setCookies] = useState({});
    const [response, setResponse] = useState(null);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
    const [timeRemaining, setTimeRemaining] = useState(null);
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [platform, setPlatform] = useState(detectPlatform());
    const [cookieStatus, setCookieStatus] = useState({
        browser: {},
        localStorage: {},
        total: 0
    });

    // Add session update handler
    const handleSessionUpdate = useCallback((tokenData) => {
        const {
            accessToken,
            accessTokenExpiration,
            refreshTokenExpiration,
            userId
        } = tokenData;

        // Update local storage
        if (accessToken) {
            localStorage.setItem('accessToken', accessToken);
            localStorage.setItem('accessTokenExpiration', JSON.stringify(accessTokenExpiration));
        }
        if (refreshTokenExpiration) {
            localStorage.setItem('refreshTokenExpiration', JSON.stringify(refreshTokenExpiration));
        }

        // Update auth context
        updateSession({
            accessToken,
            accessTokenExpiration,
            refreshTokenExpiration,
            userId
        });

        // Reset time remaining counter
        if (accessTokenExpiration) {
            const now = Date.now();
            const remaining = accessTokenExpiration - now;
            setTimeRemaining(remaining > 0 ? formatTimeRemaining(remaining) : 'Session expired');
        }
    }, [updateSession]);

    // Add log helper
    const addLog = useCallback((type, message, details = null) => {
        const timestamp = new Date().toISOString();
        setLogs(prev => [...prev, {
            type,
            message: typeof message === 'object' ? JSON.stringify(message, null, 2) : message,
            details: details ? JSON.stringify(details, null, 2) : null,
            timestamp
        }]);
        
        // Also log to console for debugging
        console.log(`[${timestamp}] ${type}:`, message, details || '');
    }, []);

    // Fetch cookies and platform info when the component mounts
    useEffect(() => {
        const fetchCookiesAndPlatform = async () => {
            try {
                const response = await axiosInstance.get('/api/check-cookies-ios', {
                    withCredentials: true
                });
                const data = response.data;
                setCookieStatus({
                    browser: data.cookies || {},
                    localStorage: handleCookies.getAll().localStorage,
                    total: Object.keys(data.cookies || {}).length + Object.keys(handleCookies.getAll().localStorage).length
                });
                setPlatform(detectPlatform());
                addLog('info', 'Cookies and platform info retrieved:', data);
            } catch (error) {
                addLog('error', 'Failed to fetch cookies and platform info:', error);
            }
        };

        fetchCookiesAndPlatform();
    }, [addLog]);

    const getRefreshTokenFromCookies = useCallback(() => {
        return handleCookies.get('refreshToken');
    }, []);

    // Detailed token info logging
    const tokenInfo = useMemo(() => ({
        accessToken: localStorage.getItem('accessToken'),
        accessTokenExpiration: localStorage.getItem('accessTokenExpiration'),
        refreshTokenExpiration: localStorage.getItem('refreshTokenExpiration'),
        contextTokenExpiration,
        contextRefreshExpiration
    }), [contextTokenExpiration, contextRefreshExpiration]);

    // Enhanced auth validation
    const authState = useMemo(() => {
        const userId = user?._id || user?.id;
        const now = Date.now();
        
        const tokenExpirationTime = contextTokenExpiration || 
            (tokenInfo.accessTokenExpiration ? JSON.parse(tokenInfo.accessTokenExpiration) : null);
        
        const refreshExpirationTime = contextRefreshExpiration ||
            (tokenInfo.refreshTokenExpiration ? JSON.parse(tokenInfo.refreshTokenExpiration) : null);

        const reasons = [];
        if (!isAuthenticated) reasons.push('not authenticated');
        if (!userId) reasons.push('missing user ID');
        if (!accessToken) reasons.push('missing access token');
        if (!tokenExpirationTime) reasons.push('missing token expiration');
        if (tokenExpirationTime && tokenExpirationTime < now) reasons.push('token expired');

        const validationDetails = {
            isAuthenticated,
            hasUser: !!user,
            hasUserId: !!userId,
            hasAccessToken: !!accessToken,
            hasTokenExpiration: !!tokenExpirationTime,
            tokenExpired: tokenExpirationTime ? tokenExpirationTime < now : null,
            currentTime: now,
            expirationTime: tokenExpirationTime
        };

        addLog('debug', 'Auth state validation:', validationDetails);

        return {
            isValid: reasons.length === 0,
            reasons,
            userId,
            tokenExpirationTime: tokenExpirationTime ? new Date(tokenExpirationTime) : null,
            refreshExpirationTime: refreshExpirationTime ? new Date(refreshExpirationTime) : null,
            message: reasons.length > 0 ? `Authentication invalid: ${reasons.join(', ')}` : null,
            details: validationDetails
        };
    }, [isAuthenticated, user, accessToken, contextTokenExpiration, contextRefreshExpiration, tokenInfo, addLog]);

    // Helpers
    const clearLogs = useCallback(() => {
        setLogs([]);
        setRequestHeaders(null);
        setResponse(null);
        setCookies({});
    }, []);

    const updateCookies = useCallback(async () => {
        try {
            addLog('info', '🍪 Fetching current cookies');
            const response = await axiosInstance.get('/api/check-cookies', {
                withCredentials: true
            });

            const allCookies = handleCookies.getAll();
            setCookieStatus(allCookies);
            addLog('info', 'Cookies retrieved:', allCookies);
        } catch (error) {
            if (error.response && error.response.status === 400) {
                addLog('error', 'Missing or invalid cookies:', error.response.data);
            } else {
                addLog('error', 'Failed to fetch cookies:', error);
            }
        }
    }, [addLog]);

    // Effect for platform detection updates
    useEffect(() => {
        const updatePlatform = () => {
            setPlatform(detectPlatform());
        };

        // Update on mount and window resize
        updatePlatform();
        window.addEventListener('resize', updatePlatform);
        return () => window.removeEventListener('resize', updatePlatform);
    }, []);

    const handleApiError = useCallback((error, action) => {
        const errorDetails = {
            message: error.message,
            status: error.response?.status,
            data: error.response?.data,
            config: error.config
        };

        addLog('error', `❌ Error ${action}:`, errorDetails);
        
        if (error.response?.status === 401) {
            addLog('warning', '🔒 Authentication expired or invalid');
        }

        setSnackbar({
            open: true,
            message: error.response?.data?.message || error.message,
            severity: 'error'
        });
    }, [addLog]);


    const fetchCookies = async () => {
        try {
            const response = await axiosInstance.get('/api/check-cookies', { withCredentials: true });
            console.log('Backend-retrieved cookies:', response.data.cookies);
        } catch (error) {
            console.error('Failed to fetch cookies:', error);
        }
    };
    fetchCookies();
    


    // Token operations
    const testTokenGeneration = async () => {
        if (!authState.isValid) {
            setSnackbar({
                open: true,
                message: authState.message,
                severity: 'warning'
            });
            return;
        }
    
        try {
            addLog('info', '🚀 Starting token generation');
            const response = await axiosInstance.post('/api/auth/generate-tokens', { userId: authState.userId }, {
                headers: { 'Authorization': `Bearer ${accessToken}` },
                withCredentials: true
            });
    
            setRequestHeaders(response.config.headers);
            setResponse(response.data);
            await updateCookies();
    
            addLog('success', '✅ Tokens generated successfully');
            addLog('info', 'Response data:', response.data);

            console.log('Stored cookies after token generation:', document.cookie);

    
            const cookies = document.cookie.split(';').reduce((acc, cookie) => {
                const [key, value] = cookie.split('=');
                acc[key.trim()] = value;
                return acc;
            }, {});
            
            console.log('All cookies:', cookies);
            console.log('Refresh token:', cookies.refreshToken);


            if (response.data.accessToken) {
                localStorage.setItem('accessToken', response.data.accessToken);
                localStorage.setItem('accessTokenExpiration', 
                    JSON.stringify(response.data.accessTokenExpiration));
            }
    
            setSnackbar({
                open: true,
                message: 'Tokens generated successfully',
                severity: 'success'
            });
        } catch (error) {
            handleApiError(error, 'generating tokens');
        }
    };

    const testRefreshToken = async () => {
        try {
            addLog('info', '🔄 Starting token refresh');
    
            const response = await axiosInstance.post('/api/auth/refresh-token', {}, {
                withCredentials: true  // ✅ Ensures the browser sends HttpOnly cookies
            });
    
            // Success handling
            addLog('success', '✅ Tokens refreshed successfully');
            addLog('info', 'Response data:', response.data);
    
            // Update local storage with new tokens
            if (response.data.accessToken) {
                localStorage.setItem('accessToken', response.data.accessToken);
                localStorage.setItem('accessTokenExpiration', JSON.stringify(response.data.accessTokenExpiration));
            }
    
            setSnackbar({
                open: true,
                message: 'Tokens refreshed successfully',
                severity: 'success',
            });
    
        } catch (error) {
            if (error.response?.status === 401) {
                addLog('error', '❌ Refresh token invalid or expired', {
                    status: error.response?.status,
                    message: error.response?.data?.message,
                    debug: error.response?.data?.debug
                });
            } else {
                handleApiError(error, 'refreshing tokens');
            }
        }
    };
    

    const handleCopyAll = async (data) => {
        try {
            await navigator.clipboard.writeText(JSON.stringify(data, null, 2));
            setSnackbar({
                open: true,
                message: 'Copied to clipboard',
                severity: 'success'
            });
        } catch (error) {
            setSnackbar({
                open: true,
                message: 'Failed to copy to clipboard',
                severity: 'error'
            });
        }
    };

    const handleCopyLogs = async () => {
        try {
            const logsText = logs.map(log => 
                `[${new Date(log.timestamp).toLocaleTimeString()}] ${log.type.toUpperCase()}: ${formatResponse(log.message)}${log.details ? `\n${formatResponse(log.details)}` : ''}`
            ).join('\n\n');
                        await navigator.clipboard.writeText(logsText);
            setSnackbar({
                open: true,
                message: 'Console & Server Logs copied to clipboard',
                severity: 'success'
            });
        } catch (error) {
            setSnackbar({
                open: true,
                message: 'Failed to copy logs to clipboard',
                severity: 'error'
            });
        }
    };

    const handleCloseSnackbar = () => {
        setSnackbar(prev => ({ ...prev, open: false }));
    };

    // Calculate remaining time until token expiration
    useEffect(() => {
        if (authState.tokenExpirationTime) {
            const interval = setInterval(() => {
                const now = Date.now();
                const remaining = authState.tokenExpirationTime - now;
                setTimeRemaining(remaining > 0 ? formatTimeRemaining(remaining) : 'Session expired');
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [authState.tokenExpirationTime]);

    // Render enhanced cookie section
    const renderEnhancedCookieSection = () => (
        <Box sx={{ mb: 3 }}>
            <Typography variant="h6" gutterBottom>Cookie & Platform Status</Typography>
            <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 2 }}>
                {/* Platform Info */}
                <Paper sx={{ p: 2 }}>
                    <Typography variant="subtitle2" color="primary" gutterBottom>Platform Details</Typography>
                    <Box sx={{ ml: 2 }}>
                        <Typography variant="body2">Device Type: {platform.deviceType}</Typography>
                        <Typography variant="body2">
                            Browser: {platform.isChrome ? 'Chrome' : platform.isSafari ? 'Safari' : 'Other'}
                        </Typography>
                        <Typography variant="body2">Mobile: {platform.isMobile ? 'Yes' : 'No'}</Typography>
                        <Typography variant="body2">iOS: {platform.isIOS ? 'Yes' : 'No'}</Typography>
                    </Box>
                </Paper>

                {/* Cookie Stats */}
                <Paper sx={{ p: 2 }}>
                    <Typography variant="subtitle2" color="primary" gutterBottom>Cookie Statistics</Typography>
                    <Box sx={{ ml: 2 }}>
                        <Typography variant="body2">Total Cookies: {cookieStatus.total}</Typography>
                        <Typography variant="body2">
                            Browser Cookies: {Object.keys(cookieStatus.browser).length}
                        </Typography>
                        <Typography variant="body2">
                            LocalStorage Cookies: {Object.keys(cookieStatus.localStorage).length}
                        </Typography>
                    </Box>
                </Paper>
            </Box>

            {/* Cookie Verification Component */}
            <Box sx={{ mt: 2 }}>
                <CookieVerification refreshInterval={5000} /> {/* Set refresh interval to 5 seconds */}
            </Box>
        </Box>
    );

    // Render method for the active cookies table
    const renderActiveCookiesTable = () => (
        <Box sx={{ mb: 2 }}>
            <Typography variant="body2" sx={{ mb: 1 }}><strong>Active Cookies</strong></Typography>
            <TableContainer component={Paper} sx={{ maxHeight: '200px' }}>
                <Table size="small" stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell><strong>Name</strong></TableCell>
                            <TableCell><strong>Value</strong></TableCell>
                            <TableCell><strong>Storage</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.entries(cookieStatus.browser).map(([name, value]) => (
                            <TableRow key={`browser-${name}`}>
                                <TableCell>{name}</TableCell>
                                <TableCell>{value}</TableCell>
                                <TableCell>Browser</TableCell>
                            </TableRow>
                        ))}
                        {Object.entries(cookieStatus.localStorage).map(([name, value]) => (
                            <TableRow key={`storage-${name}`}>
                                <TableCell>{name}</TableCell>
                                <TableCell>{value}</TableCell>
                                <TableCell>LocalStorage</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );

   // Main render
   return (
    <>
      <Header />
      <Paper elevation={3} sx={{ p: 3, mt: 3 }}>
        {/* Status Header */}
        <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6">Token Testing Dashboard</Typography>
          <Box
            sx={{
              p: 1,
              borderRadius: 1,
              bgcolor: authState.isValid ? 'success.light' : 'error.light',
              color: 'white',
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}
          >
            {isRefreshing ? (
              <CircularProgress size={20} color="inherit" />
            ) : authState.isValid ? (
              <>
                <span>✓ Fully Authenticated</span>
                {timeRemaining && (
                  <Typography variant="caption" sx={{ opacity: 0.8 }}>
                    ({timeRemaining})
                  </Typography>
                )}
              </>
            ) : (
              <span>✗ Authentication Invalid</span>
            )}
          </Box>
        </Box>
  
        {/* Enhanced Cookie Section */}
        {renderEnhancedCookieSection()}
  
        {/* User Info Section */}
        {user && (
          <Box sx={{ mb: 2, p: 2, bgcolor: 'grey.100', borderRadius: 1 }}>
            <Typography variant="body2">Email: {user.email}</Typography>
            <Typography variant="body2">ID: {authState.userId || 'Missing'}</Typography>
            <Typography variant="body2">
              Access Token: {accessToken ? (
                <span style={{ color: 'green' }}>✓ Present</span>
              ) : (
                <span style={{ color: 'red' }}>✗ Missing</span>
              )}
            </Typography>
            <Typography variant="body2">
              Token Expires: {authState.tokenExpirationTime?.toLocaleString() || 'Unknown'}
            </Typography>
            <Typography variant="body2" color={timeRemaining === 'Session expired' ? 'error.main' : 'text.secondary'}>
              Time Remaining: {timeRemaining}
            </Typography>
            {authError && (
              <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                Error: {authError}
              </Typography>
            )}
          </Box>
        )}
  
        {/* Action Buttons */}
        <Divider sx={{ my: 2 }} />
        <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
          <Button
            variant="contained"
            startIcon={<RefreshIcon />}
            onClick={testTokenGeneration}
            disabled={!authState.isValid || isRefreshing}
          >
            Generate Tokens
          </Button>
          <Button
            variant="contained"
            startIcon={<RefreshIcon />}
            onClick={testRefreshToken}
            disabled={!authState.isValid || isRefreshing}
          >
            Refresh Tokens
          </Button>
          <Button
            variant="outlined"
            startIcon={<ContentCopyIcon />}
            onClick={() => handleCopyAll(response)}
            disabled={!response}
          >
            Copy Response
          </Button>
        </Box>
  
        {/* Logs and Response Sections */}
        <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant="body2" sx={{ mb: 1 }}>
            <strong>Console & Server Logs</strong>
          </Typography>
          <IconButton onClick={handleCopyLogs} aria-label="copy logs" size="small">
            <ContentCopyIcon fontSize="small" />
          </IconButton>
        </Box>
        <TableContainer component={Paper} sx={{ maxHeight: '400px' }}>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>
                  <strong>Timestamp</strong>
                </TableCell>
                <TableCell>
                  <strong>Type</strong>
                </TableCell>
                <TableCell>
                  <strong>Message</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {logs.map((log, index) => (
                <TableRow key={index}>
                  <TableCell>{new Date(log.timestamp).toLocaleTimeString()}</TableCell>
                  <TableCell
                    style={{
                      color:
                        log.type === 'error'
                          ? '#dc3545'
                          : log.type === 'success'
                          ? '#28a745'
                          : log.type === 'warning'
                          ? '#ffc107'
                          : '#212529',
                    }}
                  >
                    {log.type.toUpperCase()}
                  </TableCell>
                  <TableCell>
                    <pre
                      style={{
                        background: 'none',
                        padding: 0,
                        margin: 0,
                        whiteSpace: 'pre-wrap',
                        wordWrap: 'break-word',
                        fontSize: '12px',
                        fontFamily: 'monospace',
                      }}
                    >
                      {formatResponse(log.message)}
                      {log.details && (
                        <div style={{ marginTop: '4px', color: '#6c757d' }}>{formatResponse(log.details)}</div>
                      )}
                    </pre>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
  
        <Box sx={{ mb: 2 }}>
          <Typography variant="body2" sx={{ mb: 1 }}>
            <strong>Request Headers</strong>
          </Typography>
          <pre
            style={{
              background: '#f5f5f5',
              padding: '10px',
              borderRadius: '4px',
              maxHeight: '200px',
              overflowY: 'auto',
            }}
          >
            {requestHeaders ? JSON.stringify(requestHeaders, null, 2) : 'No headers logged'}
          </pre>
        </Box>
  
        <Box sx={{ mb: 2 }}>
          <Typography variant="body2" sx={{ mb: 1 }}>
            <strong>Cookies</strong>
          </Typography>
          <TableContainer component={Paper} sx={{ maxHeight: '200px' }}>
            <Table size="small" stickyHeader>
              <TableHead>
                <TableRow></TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(cookieStatus.browser).map(([name, value]) => (
                  <TableRow key={`browser-${name}`}>
                    <TableCell>{name}</TableCell>
                    <TableCell>{value}</TableCell>
                    <TableCell>Browser</TableCell>
                  </TableRow>
                ))}
                {Object.entries(cookieStatus.localStorage).map(([name, value]) => (
                  <TableRow key={`storage-${name}`}>
                    <TableCell>{name}</TableCell>
                    <TableCell>{value}</TableCell>
                    <TableCell>LocalStorage</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
  
        <Box sx={{ mb: 2 }}>
          <Typography variant="body2" sx={{ mb: 1 }}>
            <strong>Response</strong>
          </Typography>
          <pre
            style={{
              background: '#f5f5f5',
              padding: '10px',
              borderRadius: '4px',
              maxHeight: '200px',
              overflowY: 'auto',
            }}
          >
            {response ? JSON.stringify(response, null, 2) : 'No response logged'}
          </pre>
        </Box>
  
        <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Paper>
    </>
  );
  
};

export default TokenTester;