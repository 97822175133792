import React, { useEffect, useState } from 'react';
import {
  Alert,
  AlertTitle,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  Tabs,
  Tab,
  Chip,
  Card,
  CardContent,
  Grid,
  Collapse,
  IconButton,
  TextField,
  InputAdornment,
  Tooltip,
  Divider,
  Button,
  Menu,
  MenuItem,
  Stack,
  LinearProgress,
  Switch,
  FormControlLabel,

} from '@mui/material';
import {
  Search as SearchIcon,
  TrendingUp as TrendingUpIcon,
  Timeline as TimelineIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  Schedule as ScheduleIcon,
  Person as PersonIcon,
  Flag as FlagIcon,
  DeviceHub as DeviceHubIcon,
  FilterList as FilterListIcon,
  Public as PublicIcon,
  DateRange as DateRangeIcon,
  KeyboardReturn as KeyboardReturnIcon
} from '@mui/icons-material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip as ChartTooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import axiosInstance from '../services/api.js';

import Header from '../components/Header.js';

  
 

// Admin IPs and detection
const ADMIN_IPS = [
  '127.0.0.1',
  '::1',
  'localhost',
  '192.168.1.1', // Add your admin IPs here
];

const isAdminIP = (ip) => ADMIN_IPS.includes(ip);







// TabPanel Component
const TabPanel = ({ children, value, index, ...other }) => (
  <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} {...other}>
    {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
  </div>
);

// Visitor Row Component
const VisitorRow = ({ visitor, showAdminEntries }) => {
  const [open, setOpen] = useState(false);
  const isAdmin = isAdminIP(visitor.ip);


  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography 
              variant="body2" 
              color={isAdmin ? 'primary.main' : 'text.primary'}
              sx={{ fontWeight: isAdmin ? 'bold' : 'normal' }}
            >
              {isAdmin ? 'Admin' : (visitor.userId || 'Anonymous')}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              {visitor.userEmail || 'No email'}
            </Typography>
            <Chip 
              size="small" 
              label={visitor.isReturning ? 'Return Visitor' : 'New Visitor'}
              color={visitor.isReturning ? 'primary' : 'default'}
              sx={{ mt: 0.5, maxWidth: 100 }}
            />
            {isAdmin && (
              <Chip 
                size="small" 
                label="Admin"
                color="secondary"
                sx={{ mt: 0.5, maxWidth: 100 }}
              />
            )}
          </Box>
        </TableCell>
        <TableCell>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <FlagIcon sx={{ mr: 1, fontSize: 'small' }} />
            {visitor.country || 'Unknown'}
          </Box>
        </TableCell>
        <TableCell>
          <Chip 
            size="small" 
            label={visitor.isActive ? 'Active' : 'Inactive'}
            color={visitor.isActive ? 'success' : 'default'}
          />
        </TableCell>
        <TableCell>{new Date(visitor.timestamp).toLocaleString()}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Grid container spacing={3} sx={{ mb: 2 }}>
                <Grid item xs={12} md={4}>
                  <Paper sx={{ p: 2 }}>
                    <Typography variant="h6" gutterBottom component="div">
                      Session Details
                    </Typography>
                    <Stack spacing={1}>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography variant="body2" color="textSecondary">Duration:</Typography>
                        <Typography variant="body2">{visitor.timeSpent || 'N/A'}</Typography>
                      </Box>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography variant="body2" color="textSecondary">Entry Page:</Typography>
                        <Typography variant="body2">{visitor.entryPage || '/'}</Typography>
                      </Box>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography variant="body2" color="textSecondary">Exit Page:</Typography>
                        <Typography variant="body2">{visitor.exitPage || 'N/A'}</Typography>
                      </Box>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography variant="body2" color="textSecondary">Referrer:</Typography>
                        <Typography variant="body2" sx={{ maxWidth: '70%', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                          {visitor.referrer}
                        </Typography>
                      </Box>
                    </Stack>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Paper sx={{ p: 2 }}>
                    <Typography variant="h6" gutterBottom component="div">
                      Location Info
                    </Typography>
                    <Stack spacing={1}>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography variant="body2" color="textSecondary">IP:</Typography>
                        <Typography variant="body2">{visitor.ip}</Typography>
                      </Box>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography variant="body2" color="textSecondary">Country:</Typography>
                        <Typography variant="body2">{visitor.country || 'Unknown'}</Typography>
                      </Box>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography variant="body2" color="textSecondary">City:</Typography>
                        <Typography variant="body2">{visitor.city || 'Unknown'}</Typography>
                      </Box>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography variant="body2" color="textSecondary">Region:</Typography>
                        <Typography variant="body2">{visitor.region || 'Unknown'}</Typography>
                      </Box>
                    </Stack>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Paper sx={{ p: 2 }}>
                    <Typography variant="h6" gutterBottom component="div">
                      Technical Details
                    </Typography>
                    <Typography variant="body2" sx={{ wordBreak: 'break-word' }}>
                      {visitor.userAgent}
                    </Typography>
                    {visitor.rageClicks > 0 && (
                      <Alert severity="warning" sx={{ mt: 2 }}>
                        Rage clicks detected: {visitor.rageClicks}
                      </Alert>
                    )}
                  </Paper>
                </Grid>
              </Grid>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const TrafficDetailsPage = () => {
  // State
  const [trafficData, setTrafficData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [dateRange, setDateRange] = useState('today');
  const [searchTerm, setSearchTerm] = useState('');
  const [showAdminEntries, setShowAdminEntries] = useState(false);


  const [backlinkData, setBacklinkData] = useState([]);
  const [keywordData, setKeywordData] = useState({
    keywords: [],
    total: 0,
    pages: 1,
    currentPage: 1
  });
  const [seoMetrics, setSeoMetrics] = useState({
    organicTraffic: 45000,
    paidTraffic: 15000,
    totalKeywords: 3200,
    keywordRankings: {
      top3: 125,
      top10: 450,
      top100: 2625
    },
    trafficTrend: Array.from({ length: 12 }, (_, i) => ({
      month: new Date(2024, i, 1).toLocaleString('default', { month: 'short' }),
      organic: Math.floor(Math.random() * 50000),
      paid: Math.floor(Math.random() * 20000)
    }))
  });

  // Effects
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        setError(null);
  
        const [trafficResponse, seoResponse, backlinkResponse, keywordResponse] = await Promise.all([
          axiosInstance.get('/api/stats/traffic'),
          axiosInstance.get('/api/stats/seo/metrics'),
          axiosInstance.get('/api/stats/seo/backlinks'), // Fetch backlinks
          axiosInstance.get('/api/stats/seo/keywords')

        ]);


      // Log the traffic data (including location information)
      // console.log('Traffic Data:', trafficResponse.data);
      


  
        setTrafficData(trafficResponse.data);
        setSeoMetrics(prev => ({ ...prev, ...seoResponse.data }));
        setBacklinkData(backlinkResponse.data.backlinks || []);
        setKeywordData(keywordResponse.data);

        
        // if (backlinkResponse.data.backlinks) {
        //   setBacklinkData(backlinkResponse.data.backlinks); // ✅ Fix Here
        // }
  
        // console.log('Traffic Data:', trafficResponse.data);
        // console.log('SEO Metrics:', seoResponse.data);
        // console.log('Backlink Data:', backlinkResponse.data);
        // console.log('keywordResponse Data:', keywordResponse.data);


        

      } catch (error) {
        setError(error.message || 'Failed to fetch data');
        console.error('❌ Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, []);
  

  


  // Handlers
  const handleFilterClick = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };

  const handleDateRangeChange = (range) => {
    setDateRange(range);
    handleFilterClose();
  };

  // Render Functions
  const renderOverviewCards = () => {
    // Calculate visitors excluding admins
    const nonAdminVisitors = trafficData.filter(visitor => !ADMIN_IPS.includes(visitor.ip));
    const activeVisitors = trafficData.filter(visitor => visitor.isActive);
    const nonAdminActiveVisitors = activeVisitors.filter(visitor => !ADMIN_IPS.includes(visitor.ip));
  
    return (
      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} sm={6} md={3}>
          <Card>
            <CardContent>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                <Typography color="textSecondary">
                  Total Visitors
                </Typography>
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      checked={showAdminEntries}
                      onChange={(e) => setShowAdminEntries(e.target.checked)}
                    />
                  }
                  label={
                    <Typography variant="caption">
                      Include Admin
                    </Typography>
                  }
                  sx={{ mr: 0 }}
                />
              </Box>
              <Typography variant="h4">
                {showAdminEntries ? trafficData.length : nonAdminVisitors.length}
              </Typography>
              <Typography variant="body2" color="success.main" sx={{ display: 'flex', alignItems: 'center' }}>
                <PersonIcon sx={{ mr: 1 }} />
                Today's visits
                {showAdminEntries && trafficData.length !== nonAdminVisitors.length && (
                  <Tooltip title="Includes admin visits">
                    <Box component="span" sx={{ ml: 1, display: 'flex', alignItems: 'center' }}>
                      <DeviceHubIcon sx={{ fontSize: 16, color: 'primary.main' }} />
                    </Box>
                  </Tooltip>
                )}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
  
        <Grid item xs={12} sm={6} md={3}>
          <Card>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Active Users
              </Typography>
              <Typography variant="h4">
                {showAdminEntries ? activeVisitors.length : nonAdminActiveVisitors.length}
              </Typography>
              <Typography variant="body2" color="primary" sx={{ display: 'flex', alignItems: 'center' }}>
                <ScheduleIcon sx={{ mr: 1 }} />
                Current session
              </Typography>
            </CardContent>
          </Card>
        </Grid>
  
        <Grid item xs={12} sm={6} md={3}>
          <Card>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Avg Time on Site
              </Typography>
              <Typography variant="h4">
                5m 30s
              </Typography>
              <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
                <TimelineIcon sx={{ mr: 1 }} />
                Per session
              </Typography>
            </CardContent>
          </Card>
        </Grid>
  
        <Grid item xs={12} sm={6} md={3}>
          <Card>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Bounce Rate
              </Typography>
              <Typography variant="h4">
                35%
              </Typography>
              <Typography variant="body2" color="error" sx={{ display: 'flex', alignItems: 'center' }}>
                <TrendingUpIcon sx={{ mr: 1 }} />
                +2% from last week
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  };


  const renderVisitorTable = () => (
    <Box>
      <Box sx={{ mb: 3, display: 'flex', gap: 2, alignItems: 'center' }}>
        <TextField
          size="small"
          placeholder="Search visitors..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            )
          }}
          sx={{ flexGrow: 1 }}
        />
        <Button
          variant="outlined"
          startIcon={<FilterListIcon />}
          onClick={handleFilterClick}
        >
          Filter
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: '50px' }} />
              <TableCell>Visitor</TableCell>
              <TableCell>Location</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Timestamp</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {trafficData
              .filter(visitor => showAdminEntries || !isAdminIP(visitor.ip))
              .map((visitor, index) => (
                <VisitorRow 
                  key={index} 
                  visitor={visitor} 
                  showAdminEntries={showAdminEntries}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
  
  const renderBacklinksTable = () => (
    <Card>
      <CardContent>
        <Typography variant="h6" gutterBottom>Backlinks Analysis ({backlinkData.length} backlinks)</Typography>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>URL</TableCell>
                <TableCell>Anchor Text</TableCell>
                <TableCell>Domain Authority</TableCell>
                <TableCell>Page Authority</TableCell>
                <TableCell>Referring Domain</TableCell>
                <TableCell>Spam Score</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {backlinkData.map((backlink, index) => (
                <TableRow key={backlink._id || index}>
                  <TableCell>
                    <Typography variant="body2" noWrap sx={{ maxWidth: 250 }}>
                      {backlink.url}
                    </Typography>
                  </TableCell>
                  <TableCell>{backlink.anchorText}</TableCell>
                  <TableCell>
                    <Chip 
                      label={backlink.domainAuthority} 
                      color={backlink.domainAuthority > 50 ? "success" : "default"}
                      size="small"
                    />
                  </TableCell>
                  <TableCell>{backlink.pageAuthority}</TableCell>
                  <TableCell>{backlink.referringDomain}</TableCell>
                  <TableCell>
                    <Chip 
                      label={backlink.spamScore}
                      color={backlink.spamScore < 30 ? "success" : "error"}
                      size="small"
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
  
  const renderKeywordTracking = () => (
    <Card>
      <CardContent>
        <Typography variant="h6" gutterBottom>Keyword Rankings</Typography>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Keyword</TableCell>
                <TableCell>Position</TableCell>
                <TableCell>Monthly Searches</TableCell>
                <TableCell>Trend</TableCell>
                <TableCell>Difficulty</TableCell>
                <TableCell>Search Intent</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {keywordData?.keywords?.map((keyword, index) => (
                <TableRow key={index}>
                  <TableCell>{keyword.keyword}</TableCell>
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      {keyword.position}
                      {keyword.trend === 'up' ? (
                        <KeyboardArrowUpIcon color="success" />
                      ) : (
                        <KeyboardArrowDownIcon color="error" />
                      )}
                      <Typography variant="caption" color={keyword.trend === 'up' ? 'success.main' : 'error.main'}>
                        {Math.abs(keyword.change)}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>{keyword.monthlySearches?.toLocaleString()}</TableCell>
                  <TableCell>
                    <LinearProgress
                      variant="determinate"
                      value={keyword.difficulty}
                      sx={{ width: 100 }}
                    />
                  </TableCell>
                  <TableCell>
                    <Chip 
                      label={keyword.intent} 
                      size="small"
                      color={
                        keyword.intent === 'transactional' ? 'success' :
                        keyword.intent === 'informational' ? 'primary' :
                        'default'
                      }
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );


 
  const renderTrafficChart = () => (
    <Card sx={{ mt: 2 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>Traffic Trends</Typography>
        <Box sx={{ height: 250 }}>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={seoMetrics.trafficTrend}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis />
              <ChartTooltip />
              <Legend />
              <Line type="monotone" dataKey="organic" stroke="#4caf50" name="Organic" />
              <Line type="monotone" dataKey="paid" stroke="#2196f3" name="Paid" />
            </LineChart>
          </ResponsiveContainer>
        </Box>
      </CardContent>
    </Card>
  );

  if (loading) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" minHeight="100vh">
        <CircularProgress />
        <Typography variant="h6" sx={{ ml: 2 }}>
          Loading analytics...
        </Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box p={4}>
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {error}
        </Alert>
      </Box>
    );
  }

  return (

    <Box
    sx={{
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column",
      backgroundColor: "#000",
      overflow: "hidden",
      margin: 0,
      padding: 0,
    }}
  >
    <Header />


    <Box p={4}>

      <Typography variant="h4" gutterBottom>
        Website Analytics & Traffic
      </Typography>

      {renderOverviewCards()}
      </Box>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <Tabs
          value={tabValue}
          onChange={(e, newValue) => setTabValue(newValue)}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="traffic analytics tabs"
        >
    <Tab label="Visitor Details" icon={<PersonIcon />} iconPosition="start" />
    <Tab label="Traffic Overview" icon={<TimelineIcon />} iconPosition="start" />
    <Tab label="Geographic Data" icon={<PublicIcon />} iconPosition="start" />
    <Tab label="Behavior Analytics" icon={<DeviceHubIcon />} iconPosition="start" />
    <Tab label="Backlinks" icon={<DeviceHubIcon />} iconPosition="start" />
    <Tab label="Keywords" icon={<SearchIcon />} iconPosition="start" />
        </Tabs>
      </Paper>

      <TabPanel value={tabValue} index={0}>
        {renderVisitorTable()}
      </TabPanel>

      <TabPanel value={tabValue} index={1}>
          {/* Traffic Overview content */}

        <Grid container spacing={3}>
          <Grid item xs={12}>
            {renderTrafficChart()}
          </Grid>
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>Traffic Sources</Typography>
                <Box sx={{ height: 300 }}>
                  <ResponsiveContainer width="100%" height="100%">
                    <PieChart>
                      <Pie
                        data={[
                          { name: 'Direct', value: 400 },
                          { name: 'Organic', value: 300 },
                          { name: 'Referral', value: 200 },
                          { name: 'Social', value: 100 }
                        ]}
                        cx="50%"
                        cy="50%"
                        outerRadius={80}
                        dataKey="value"
                        label
                      >
                        <Cell fill="#4caf50" />
                        <Cell fill="#2196f3" />
                        <Cell fill="#ff9800" />
                        <Cell fill="#f44336" />
                      </Pie>
                      <ChartTooltip />
                      <Legend />
                    </PieChart>
                  </ResponsiveContainer>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>Visitor Type</Typography>
                <Box sx={{ height: 300 }}>
                  <ResponsiveContainer width="100%" height="100%">
                    <PieChart>
                      <Pie
                        data={[
                          { name: 'New Visitors', value: trafficData.filter(v => !v.isReturning).length },
                          { name: 'Returning', value: trafficData.filter(v => v.isReturning).length }
                        ]}
                        cx="50%"
                        cy="50%"
                        outerRadius={80}
                        dataKey="value"
                        label
                      >
                        <Cell fill="#2196f3" />
                        <Cell fill="#4caf50" />
                      </Pie>
                      <ChartTooltip />
                      <Legend />
                    </PieChart>
                  </ResponsiveContainer>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </TabPanel>

      <TabPanel value={tabValue} index={2}>
          {/* Geographic Data content */}

        <Typography variant="h6" gutterBottom>Geographic Distribution</Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="subtitle1" gutterBottom>Top Countries</Typography>
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Country</TableCell>
                        <TableCell align="right">Visitors</TableCell>
                        <TableCell align="right">% of Total</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.entries(
                        trafficData.reduce((acc, curr) => {
                          const country = curr.country || 'Unknown';
                          acc[country] = (acc[country] || 0) + 1;
                          return acc;
                        }, {})
                      )
                        .sort(([,a], [,b]) => b - a)
                        .map(([country, count]) => (
                          <TableRow key={country}>
                            <TableCell>{country}</TableCell>
                            <TableCell align="right">{count}</TableCell>
                            <TableCell align="right">
                              {((count / trafficData.length) * 100).toFixed(1)}%
                            </TableCell>
                          </TableRow>
                        ))
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </TabPanel>

      <TabPanel value={tabValue} index={3}>
          {/* Behavior Analytics content */}

        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>Most Visited Pages</Typography>
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Page</TableCell>
                        <TableCell align="right">Visits</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.entries(
                        trafficData.reduce((acc, curr) => {
                          const page = curr.entryPage || '/';
                          acc[page] = (acc[page] || 0) + 1;
                          return acc;
                        }, {})
                      )
                        .sort(([,a], [,b]) => b - a)
                        .slice(0, 5)
                        .map(([page, count]) => (
                          <TableRow key={page}>
                            <TableCell>{page}</TableCell>
                            <TableCell align="right">{count}</TableCell>
                          </TableRow>
                        ))
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>User Engagement</Typography>
                {/* Add engagement metrics here */}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </TabPanel>


      <TabPanel value={tabValue} index={4}>
      {renderBacklinksTable()}
      </TabPanel>

      <TabPanel value={tabValue} index={5}>
      {renderKeywordTracking()}
      </TabPanel>

    </Box>
  );
};

// ✅ Ensure TrafficInfoPage is properly exported
export default TrafficDetailsPage;


