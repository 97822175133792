import React, { useEffect, useState } from 'react';
import { useAuth } from '../contexts/AuthContext.js';
import { ThemeProvider } from '@mui/material/styles/index.js';
import { Box, Typography, Snackbar } from '@mui/material';
import Header from '../components/Header.js';
import Footer from '../components/Footer.js';
import theme from '../theme.js';

import SessionMonitor from '../components/SessionMonitor.js';


const ProfilePage = () => {
  const { user, loading, error, fetchUserData } = useAuth();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (!user && !loading && !error) {
      const token = localStorage.getItem('accessToken');
      if (token) {
        fetchUserData(token);
      }
    }
  }, [user, loading, error, fetchUserData]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          // backgroundColor: "#000",
          overflow: "hidden",
          margin: 0,
          padding: 0,
        }}
      >
        <Header />
        <Box sx={{ flexGrow: 1, padding: 3, backgroundColor: "#000", color: "#fff" }}>
          {user ? (
            <Box>
              <Typography variant="h4" gutterBottom>
                Profile Page

              </Typography>

              <Typography variant="h4" gutterBottom>
                <SessionMonitor showUserDetails={true} />

              </Typography>
              <Typography variant="body1">
                <strong>Email:</strong> {user.email}
              </Typography>
              <Typography variant="body1">
                <strong>Username:</strong> {user.username || 'Not set'}
              </Typography>
              <Typography variant="body1">
                <strong>Last Active Channel:</strong> {user.lastActiveChannel || 'N/A'}
              </Typography>
              <Typography variant="body1">
  <strong>Channels:</strong>{" "}
  {Array.isArray(user.channels) && user.channels.length > 0
    ? user.channels.join(", ")
    : "No channels"}
</Typography>
<Box mt={2}>
  <Typography variant="h5">Last Interacted Messages</Typography>
  {user.lastInteractedMessages && Object.keys(user.lastInteractedMessages).length > 0 ? (
    Object.entries(user.lastInteractedMessages).map(([channelId, message]) => (
      <Box key={channelId} sx={{ mt: 1 }}>
        <Typography variant="body2">
          <strong>Channel:</strong> {channelId}
        </Typography>
        <Typography variant="body2">
          <strong>Last Message:</strong> {message}
        </Typography>
      </Box>
    ))
  ) : (
    <Typography variant="body2">No messages found</Typography>
  )}
</Box>

            </Box>
          ) : (
            <Box>
              <Typography variant="h6">No user data found</Typography>
            </Box>
          )}
        </Box>
        <Footer />
        <Snackbar
          open={openSnackbar}
          autoHideDuration={5000}
          onClose={() => setOpenSnackbar(false)}
          message={errorMessage}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          sx={{
            "& .MuiSnackbarContent-root": {
              backgroundColor: "#000",
              border: "1px solid #00D632",
              color: "#FFF",
            },
          }}
        />
      </Box>
    </ThemeProvider>
  );
};

export default ProfilePage;
