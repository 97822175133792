import React from 'react';
import { createRoot } from 'react-dom/client';
import { HashRouter as Router } from 'react-router-dom';
import App from './App.js';
import { StyledEngineProvider } from '@mui/material/styles/index.js';
import { SidebarProvider } from './contexts/SidebarContext.js';
import { ChatTitleProvider } from './contexts/ChatTitleContext.js';
import UploadQueueProvider from './contexts/UploadQueueContext.js';
import './index.css';

const Root = () => {
  return (
    <Router>
      <UploadQueueProvider>
        <SidebarProvider>
          <ChatTitleProvider>
            <StyledEngineProvider injectFirst>
              <App />
            </StyledEngineProvider>
          </ChatTitleProvider>
        </SidebarProvider>
      </UploadQueueProvider>
    </Router>
  );
};

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<Root />);