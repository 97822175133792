import React, { useState, useEffect, useCallback } from 'react';
import {
    CircularProgress,
    Box,
    Typography,
    IconButton,
    useMediaQuery,
} from '@mui/material';
import { InsertDriveFile, ExpandMore, ExpandLess } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles/index.js';
import axiosInstance from '../services/api.js';

const FilesInChannel = ({ channel }) => {
    const [objects, setObjects] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [isExpanded, setIsExpanded] = useState(false);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // ✅ Detect mobile

    // Generate File URL (Storj)
    const generateFileUrl = (key) => {
        const baseUrl = "https://link.storjshare.io/s/jwxazbmhg7qirv2qmhjthxx4if3q/vau7t";
        return `${baseUrl}/${encodeURIComponent(key)}?wrap=0`;
    };

    // Fetch files from the channel
    const fetchObjects = useCallback(async () => {
        if (!channel?._id) return;

        try {
            setLoading(true);
            const response = await axiosInstance.get(`/api/files/list/${channel._id}`);
            if (response?.data?.success && Array.isArray(response.data.files)) {
                const filesWithUrls = response.data.files
                    .filter(file => file.Size > 0)
                    .map(file => ({
                        ...file,
                        url: generateFileUrl(file.Key),
                    }));
                setObjects(filesWithUrls);
            } else {
                setError('Failed to load files.');
                setObjects([]);
            }
        } catch (err) {
            setError('Error fetching files: ' + (err.response?.data?.message || err.message));
            setObjects([]);
        } finally {
            setLoading(false);
        }
    }, [channel]);

    useEffect(() => {
        if (channel) {
            fetchObjects();
        }
    }, [channel, fetchObjects]);

    return (
        <Box
            sx={{
                width: '100%',
                padding: 0,
                // backgroundColor: 'rgba(255, 0, 0, 0.1)',
                borderBottom: '1px solid #ddd',
                overflow: 'hidden',
                borderRadius: '8px',
                boxShadow: isExpanded ? '0px 4px 10px rgba(0,0,0,0.1)' : 'none',
                transition: 'all 0.3s ease-in-out',
            }}
        >
            {/* Header Section (Toggle) */}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    cursor: 'pointer',
                    padding: isMobile ?'0px 15px 0px 0px' : '10px 13px 0px 0px', // ✅ Padding condition
                    margin: isMobile ? '10px 0px 0px 0px' : '0px 0px 0px 0px', // ✅ Margin condition

                    // backgroundColor: 'red',

                }}
                onClick={() => setIsExpanded(!isExpanded)}
            >
      <Typography
        variant="h8"
        sx={{
          fontSize: '10px',
          fontWeight: 'bold',
          textAlign: 'right', // ✅ Align text to the right
          flexGrow: 1,        // ✅ Push the text to occupy available space
        }}
      >
        Files in Channel
      </Typography>
                <IconButton>
                    {isExpanded ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
            </Box>

            {/* Collapsible Content */}
            {isExpanded && (
                <Box sx={{ marginTop: 2, maxHeight: '400px', overflowY: 'auto' }}>
                    {loading ? (
                        <CircularProgress />
                    ) : error ? (
                        <Typography color="error">{error}</Typography>
                    ) : objects.length > 0 ? (
                        <Box
                            sx={{
                                display: 'grid',
                                gap: 2,
                                gridTemplateColumns: {
                                    xs: 'repeat(auto-fill, minmax(70px, 1fr))',
                                    sm: 'repeat(auto-fill, minmax(100px, 1fr))',
                                    md: 'repeat(auto-fill, minmax(120px, 1fr))',
                                },
                            }}
                        >
                            {objects.map((object, index) => (
                                <Box key={index} sx={{ textAlign: 'center', position: 'relative' }}>
                                    {/* Image Files */}
                                    {/\.(jpg|jpeg|png|gif)$/i.test(object.Key) ? (
                                        <img
                                            src={object.url}
                                            alt={object.Key}
                                            loading="lazy"
                                            style={{
                                                width: '100%',
                                                height: 'auto',
                                                borderRadius: '4px',
                                            }}
                                            onError={(e) => (e.target.style.display = 'none')}
                                        />
                                    ) : 
                                    /* Video Files */
                                    /\.(mp4|mov|webm)$/i.test(object.Key) ? (
                                        <video
                                            src={object.url}
                                            controls
                                            style={{
                                                width: '100%',
                                                height: 'auto',
                                                borderRadius: '4px',
                                            }}
                                            onError={(e) => (e.target.style.display = 'none')}
                                        />
                                    ) : (
                                    /* Other Files */
                                        <a
                                            href={object.url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{
                                                display: 'inline-block',
                                                width: '100%',
                                                height: 'auto',
                                                padding: '10px',
                                                textAlign: 'center',
                                                border: '1px solid #ddd',
                                                borderRadius: '4px',
                                                textDecoration: 'none',
                                                // backgroundColor: '#e0e0e0',
                                            }}
                                        >
                                            <InsertDriveFile style={{ fontSize: '40px', color: '#555' }} />
                                            <Typography
                                                variant="caption"
                                                sx={{
                                                    display: 'block',
                                                    fontSize: '12px',
                                                    wordBreak: 'break-word',
                                                    overflowWrap: 'break-word',
                                                    whiteSpace: 'normal',
                                                    maxWidth: '100%',
                                                }}
                                            >
                                                {object.Key.split('/').pop()}
                                            </Typography>
                                        </a>
                                    )}

                                    {/* File Metadata */}
                                    <Box sx={{ mt: 1, textAlign: 'center' }}>
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                fontSize: '12px',
                                                fontWeight: 'bold',
                                                wordBreak: 'break-word',
                                                overflowWrap: 'break-word',
                                                whiteSpace: 'normal',
                                            }}
                                        >
                                            {object.Key.split('/').pop()}
                                        </Typography>
                                        <Typography variant="body2" sx={{ fontSize: '12px' }}>
                                            {new Date(object.LastModified).toLocaleString()}
                                        </Typography>
                                        <Typography variant="body2" sx={{ fontSize: '12px' }}>
                                            {Math.round(object.Size / 1024)} KB
                                        </Typography>
                                    </Box>
                                </Box>
                            ))}
                        </Box>
                    ) : (
                        <Typography>No files available.</Typography>
                    )}
                </Box>
            )}
        </Box>
    );
};

export default FilesInChannel;