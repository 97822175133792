import React, { useState, useRef, useCallback, useEffect } from 'react';
import { 
  Box, Typography, IconButton, Dialog, DialogTitle, 
  DialogContent, DialogActions, Button
} from '@mui/material';
import { Delete, Share } from '@mui/icons-material';
import FilePreviewItem from './FilePreviewItem.js';
import FilePreviewModal from './FilePreviewModal.js';
import ShareModal from './ShareModal.js';
import MessageFileList from './MessageFileList.js';
import ProcessingIndicator from './ProcessingIndicator.js';
import FilesInChannel from './FilesInChannel.js';
import axiosInstance from '../services/api.js';

const ChatDisplay = ({ channel, messages, onMessageDeleted, onMessageUpdated, lastMessageRef }) => {
  // const lastMessageRef = useRef(null);
  const [messageUpdates, setMessageUpdates] = useState(new Map());

  const [modalState, setModalState] = useState({
    deleteConfirmOpen: false,
    messageToDelete: null,
    previewModalOpen: false,
    currentFiles: [],
    currentFileIndex: 0,
    shareModalOpen: false,
    selectedMessage: null
  });

  const MessageItem = useCallback(({ message, isLast }) => {
    const messageFiles = messageUpdates.get(message._id) || message.fileUrls || [];
    const hasContent = message?.content && message.content !== '(file attached)';
    const hasFiles = messageFiles.length > 0 || message.processingFiles?.length > 0;

    console.log('MessageItem render:', message._id, 'isLast:', isLast, 'ref exists:', !!lastMessageRef);


  // Add this effect to handle scrolling when messages change   /////////////////////////////////////////
  useEffect(() => {
    if (messages.length > 0 && lastMessageRef.current) {
      console.log('🔄 Messages updated, scrolling to bottom');
      const timer = setTimeout(() => {
        lastMessageRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'end'
        });
      }, 100);

      return () => clearTimeout(timer);
    }
  }, [messages]);




    return (
      <Box 
      ref={isLast ? lastMessageRef : null}
      sx={{ 
          mb: 2,
          backgroundColor: 'background.paper',
          borderRadius: 1,
          boxShadow: '0 1px 3px rgba(0,0,0,0.12)',
          '&:hover': {
            boxShadow: '0 2px 4px rgba(0,0,0,0.15)'
          },
          transition: 'box-shadow 0.2s'
        }}
      >
        <Box sx={{ 
          p: 1.5,
          display: 'flex',
          justifyContent: 'space-between',
          borderBottom: '1px solid',
          borderColor: 'divider'
        }}>
          <Typography variant="subtitle2">
            {message.user_email || 'Unknown User'}
          </Typography>
          <Typography variant="caption" color="text.secondary">
            ID: {message._id}
          </Typography>
        </Box>

        <Box sx={{ p: 2 }}>
          {hasContent && (
            <Typography sx={{ mb: hasFiles ? 2 : 0 }}>
              {message.content}
            </Typography>
          )}

          {hasFiles && (
            <MessageFileList
              message={message}
              handleFilePreview={(files, index) => {
                setModalState(prev => ({
                  ...prev,
                  currentFiles: files,
                  currentFileIndex: index,
                  previewModalOpen: true
                }));
              }}
            />
          )}

          {message.processingFiles?.map((file, index) => (
            !file.uploaded && <ProcessingIndicator key={index} file={file} />
          ))}

          <Box sx={{ 
            display: 'flex',
            justifyContent: 'flex-end',
            gap: 1,
            mt: 2
          }}>
            <IconButton
              size="small"
              onClick={() => setModalState(prev => ({
                ...prev,
                selectedMessage: message,
                shareModalOpen: true
              }))}
            >
              <Share fontSize="small" />
            </IconButton>
            <IconButton
              size="small"
              onClick={() => setModalState(prev => ({
                ...prev,
                messageToDelete: message,
                deleteConfirmOpen: true
              }))}
              color="error"
            >
              <Delete fontSize="small" />
            </IconButton>
          </Box>
        </Box>
      </Box>
    );
  }, [messageUpdates, lastMessageRef]);

  useEffect(() => {
    const handleFileUrlUpdate = (event) => {
      const { detail } = event;
      console.log('Received file update event:', detail);

      try {
        if (detail?.messageId && detail?.fileUrls) {
          console.log(`Updating message ID: ${detail.messageId} with new files:`, detail.fileUrls);

          setMessageUpdates(prev => {
            const updatedMap = new Map(prev);
            updatedMap.set(detail.messageId, detail.fileUrls);
            return new Map(updatedMap);
          });

          const messageToUpdate = messages.find(m => m._id === detail.messageId);
          if (messageToUpdate) {
            messageToUpdate.fileUrls = detail.fileUrls;
            messageToUpdate.processingFiles = messageToUpdate.processingFiles?.filter(file => !detail.fileUrls.includes(file.url));
          }

          if (onMessageUpdated) {
            console.log(`✅ Message ${detail.messageId} updated with new file URLs:`, detail.fileUrls);
            onMessageUpdated(detail.messageId, detail.fileUrls);
          }
        } else {
          console.warn('⚠️ Invalid file update event detail:', detail);
        }
      } catch (error) {
        console.error('❌ Error processing file update event:', error);
      }
    };

    window.addEventListener('message-file-updated', handleFileUrlUpdate);

    return () => window.removeEventListener('message-file-updated', handleFileUrlUpdate);
  }, [onMessageUpdated, messages]);

  // useEffect(() => {
  //   if (lastMessageRef.current) {
  //     requestAnimationFrame(() => {
  //       lastMessageRef.current?.scrollIntoView({ behavior: 'smooth' });
  //     });
  //   }
  // }, [messages]);

  const handleDelete = async () => {
    try {
      const response = await axiosInstance.post(
        `/api/message/channel/${channel._id}/message/${modalState.messageToDelete._id}/confirmDelete`,
        { filesToDelete: modalState.messageToDelete.fileUrls || [] }
      );

      if (response.data.success) {
        onMessageDeleted?.(modalState.messageToDelete._id, false);
        setMessageUpdates(prev => {
          const updates = new Map(prev);
          updates.delete(modalState.messageToDelete._id);
          return updates;
        });
      }
    } catch (error) {
      console.error('Delete failed:', error);
    } finally {
      setModalState(prev => ({ 
        ...prev, 
        deleteConfirmOpen: false,
        messageToDelete: null 
      }));
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Box sx={{ position: 'sticky', top: 0, bgcolor: 'background.paper', zIndex: 10, boxShadow: 1 }}>
        <FilesInChannel channel={channel} />
      </Box>

      <Box sx={{ flex: 1, overflowY: 'auto', px: 2, pt: 2, pb: { xs: 8, sm: 6 }, WebkitOverflowScrolling: 'touch' }}>
        {messages.map((message, index) => (
          <MessageItem key={message._id} message={message} isLast={index === messages.length - 1} />
        ))}
      </Box>

      <FilePreviewModal
        open={modalState.previewModalOpen}
        onClose={() => setModalState(prev => ({ ...prev, previewModalOpen: false }))}
        files={modalState.currentFiles}
        currentIndex={modalState.currentFileIndex}
      />

      <Dialog open={modalState.deleteConfirmOpen} onClose={() => setModalState(prev => ({ ...prev, deleteConfirmOpen: false }))}>
        <DialogTitle>Delete Message?</DialogTitle>
        <DialogContent>
          <Typography>This action cannot be undone.</Typography>
          {modalState.messageToDelete?.fileUrls?.length > 0 && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle2">Attached files will also be deleted:</Typography>
              {modalState.messageToDelete.fileUrls.map((url, index) => (
                <FilePreviewItem key={index} fileUrl={url} />
              ))}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setModalState(prev => ({ ...prev, deleteConfirmOpen: false }))}>Cancel</Button>
          <Button onClick={handleDelete} color="error">Delete</Button>
        </DialogActions>
      </Dialog>

      <ShareModal 
        open={modalState.shareModalOpen}
        message={modalState.selectedMessage}
        onClose={() => setModalState(prev => ({ ...prev, shareModalOpen: false }))}
      />
    </Box>
  );
};

export default React.memo(ChatDisplay);