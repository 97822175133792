import React, { useState, useRef, useCallback } from 'react';
import { Box, IconButton, TextField } from '@mui/material';
import { Send, Add } from '@mui/icons-material';
import { useUploadQueue } from '../contexts/UploadQueueContext.js';
import axiosInstance from '../services/api.js';

const ChatInput = React.memo(({ user, currentChannel, onMessageSent, scrollToBottom }) => {
  const [message, setMessage] = useState('');
  const [isSending, setIsSending] = useState(false);
  const { uploadQueue, uploadFiles, addFilesToQueue } = useUploadQueue();

// In ChatInput component, update the handleSend function
const handleSend = useCallback(async () => {
  if (!message.trim() && !uploadQueue.length) return;
  if (!currentChannel?._id || !user?._id) return;

  setIsSending(true);
  try {
    const messageData = {
      text: message.trim(),
      userId: user._id,  // Make sure this is set
      userEmail: user.email,
      channelId: currentChannel._id,
      ...(uploadQueue.length > 0 && {
        fileUrls: [],
        processingFiles: uploadQueue.map(({ file }) => ({
          name: file.name,
          size: file.size,
          type: file.type,
          status: 'pending'
        }))
      })
    };

    console.log('Sending message with data:', messageData); // Add this log

    const response = await axiosInstance.post(
      `/api/message/channel/${currentChannel._id}/send`,
      messageData
    );

    if (response.data.success) {
      if (uploadQueue.length > 0) {
        await uploadFiles(response.data.message._id, currentChannel._id);
      }
      setMessage('');
      console.log('Message sent successfully:', response.data.message); // Add this log
      onMessageSent?.(response.data.message);
    }
  } catch (error) {
    console.error('Send failed:', error);
  } finally {
    setIsSending(false);
  }
}, [message, uploadQueue, currentChannel, user, uploadFiles, onMessageSent]);

  const handleFileSelect = useCallback((e) => {
    const files = Array.from(e.target.files || []);
    if (files.length && currentChannel?._id) {
      addFilesToQueue(files, currentChannel._id);
    }
    e.target.value = '';
  }, [addFilesToQueue, currentChannel]);

  const handleKeyPress = useCallback((e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  }, [handleSend]);

  const hasContent = message.trim().length > 0 || uploadQueue.length > 0;

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        bgcolor: 'background.paper',
        borderTop: 1,
        borderColor: 'divider',
        p: 1,
        pb: {
          xs: 'env(safe-area-inset-bottom, 16px)',
          sm: 1
        }
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: 1,
          alignItems: 'flex-end',
          maxWidth: '800px',
          mx: 'auto',
          bgcolor: 'grey.50',
          borderRadius: 2,
          p: 3,
        }}
      >
        <IconButton
          component="label"
          disabled={isSending}
          size="small"
          sx={{
            color: 'text.secondary'
          }}
        >
          <Add fontSize="small" />
          <input
            type="file"
            accept="image/*,video/*,.pdf,.doc,.docx"
            multiple
            onChange={handleFileSelect}
            hidden
          />
        </IconButton>

        <TextField
          multiline
          maxRows={4}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={handleKeyPress}
          placeholder="Type a message..."
          disabled={isSending}
          sx={{
            flex: 1,
            '& .MuiInputBase-root': {
              p: 1,
              fontSize: 16,
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none'
            },
            backgroundColor: 'background.paper',
            borderRadius: 1
          }}
        />

        <IconButton
          onClick={handleSend}
          disabled={isSending || !hasContent}
          size="small"
          sx={{
            color: hasContent ? 'primary.main' : 'text.disabled',
            bgcolor: hasContent ? 'primary.light' : 'transparent',
            '&:hover': {
              bgcolor: hasContent ? 'primary.main' : 'transparent'
            }
          }}
        >
          <Send fontSize="small" />
        </IconButton>
      </Box>
    </Box>
  );
});

ChatInput.displayName = 'ChatInput';

export default ChatInput;