import React from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';

const DashboardLayout = ({ 
  header, 
  sidebar, 
  chat, 
  isSidebarOpen, 
  sidebarWidth = 270,
  headerHeight = 20, 
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detect mobile screen

  return (
    <Box 
      sx={{ 
        display: 'flex', 
        flexDirection: 'column',
        height: '100vh',
        width: '100vw',
        overflow: 'hidden',
        position: 'fixed',
        top: 0,
        left: 0,
      }}
    >
      {/* Header */}
      <Box
        sx={{ 
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          height: `${headerHeight}px`,
          zIndex: 1000,
          backgroundColor: '#fff',
        }}
      >
        {header}
      </Box>

      {/* Main Content Area */}
      <Box sx={{ 
        display: 'flex', 
        height: `calc(100vh - ${headerHeight}px)`, 
        marginTop: `${headerHeight}px` 
      }}>
        {/* Sidebar */}
        <Box 
          sx={{ 
            width: isSidebarOpen ? `${sidebarWidth}px` : '0',
            minWidth: isSidebarOpen ? `${sidebarWidth}px` : '0',
            borderRight: isSidebarOpen ? '1px solid #ddd' : 'none',
            transition: 'all 0.3s ease-in-out',
            position: 'fixed',
            left: 0,
            height: isMobile ? '80vh' : '92vh', // Dynamic height for mobile and desktop
            top: `${headerHeight}px`,
            bottom: 0,
            overflowY: 'auto',
            overflowX: 'hidden',
            backgroundColor: '#f5f5f5',
            zIndex: 999,
            visibility: isSidebarOpen ? 'visible' : 'hidden',
            opacity: isSidebarOpen ? 1 : 0,
          }}
        >
          {sidebar}
        </Box>

        {/* Chat Area */}
{/* Chat Area */}
<Box 
  id="chat-area"
  sx={{
    position: 'fixed',
    display: 'flex',                // ✅ Maintain flex layout
    flexDirection: 'column',        // ✅ Stack messages + input vertically
    flexGrow: 1,
    left: isSidebarOpen ? `${sidebarWidth}px` : 0,
    width: isSidebarOpen ? `calc(100% - ${sidebarWidth}px)` : '100%',
    top: `${headerHeight}px`,
    right: 0,
    bottom: 0,
    transition: 'all 0.3s ease-in-out',
    overflow: 'hidden',
    height: 'calc(100dvh - 30px)',  // ✅ Dynamic viewport height (fixes mobile issues)
    minHeight: '0',                 // ✅ Prevent shrinking when keyboard opens
    maxHeight: '100dvh',            // ✅ Limit height to viewport
    willChange: 'transform',        // ✅ Improve rendering during resize
  }}
>
  {chat}
</Box>

      </Box>
    </Box>
  );
};

export default DashboardLayout;
