import React from 'react';
import { Card, CardMedia, CardContent, Typography, Box, CircularProgress, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

const MessageFilePreview = ({ file, isProcessing, isMobile, onRemove }) => {
  // Handle preview URL based on file type and state
  const getPreviewUrl = React.useCallback(() => {
    if (!file) return 'https://placehold.co/300x200/EEE/31343C';
    
    // If we have a previewUrl from a completed upload, use that
    if (file.previewUrl) return file.previewUrl;
    
    // If it's an image being processed and we have the actual file object
    if (file.type?.startsWith('image/') && file instanceof File) {
      try {
        return URL.createObjectURL(file);
      } catch (error) {
        console.error('Failed to create object URL:', error);
        return 'https://placehold.co/300x200/EEE/31343C';
      }
    }
    
    // Default placeholder for non-image files or when no preview is available
    return 'https://placehold.co/300x200/EEE/31343C';
  }, [file]);

  // Clean up object URL when component unmounts
  React.useEffect(() => {
    const previewUrl = getPreviewUrl();
    return () => {
      if (previewUrl && previewUrl.startsWith('blob:')) {
        URL.revokeObjectURL(previewUrl);
      }
    };
  }, [getPreviewUrl]);

  return (
    <Card sx={{ 
      position: 'relative',
      borderRadius: 2,
      boxShadow: 3,
      maxWidth: isMobile ? 120 : 150,
      mx: 'auto',
      overflow: 'hidden'
    }}>
      <Box sx={{ position: 'relative' }}>
        <CardMedia
          component="img"
          height={isMobile ? 30 : 70}
          image={getPreviewUrl()}
          alt={file?.name || 'File preview'}
          sx={{ 
            objectFit: 'cover',
            filter: isProcessing ? 'blur(0px)' : 'none',
            transition: 'filter 0.3s ease'
          }}
        />
        {isProcessing && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'rgba(255, 255, 255, 0.7)'
            }}
          >
            <CircularProgress size={24} />
          </Box>
        )}

        {isProcessing && onRemove && (
          <IconButton
            size="small"
            sx={{
              position: 'absolute',
              top: 4,
              right: 4,
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 1)',
              },
              zIndex: 2,
            }}
            onClick={onRemove}
          >
            <Close fontSize="small" />
          </IconButton>
        )}
      </Box>
      <CardContent sx={{ padding: 1 }}>
        <Typography variant="body2" noWrap>
          {file?.name || 'Processing...'}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default React.memo(MessageFilePreview);