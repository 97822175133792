import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useAuth } from '../contexts/AuthContext.js';
import axiosInstance from '../services/api.js';
import {
  CircularProgress, Box, Typography, useTheme, useMediaQuery
} from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import ChatDisplay from './ChatDisplay.js';
import ChatInput from './ChatInput.js';

const Chat = ({ channel, isSidebarOpen, sidebarWidth = 270 }) => {
  const { user } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  const [messages, setMessages] = useState([]);
  const [loadingMessages, setLoadingMessages] = useState(false);
  const [error, setError] = useState(null);
  const lastMessageRef = useRef(null);
  const abortControllerRef = useRef(null);

  // Optimized scroll to bottom with debounce
  const scrollToBottom = useCallback((behavior = 'smooth') => {
    if (!lastMessageRef.current) return;

    requestAnimationFrame(() => {
      lastMessageRef.current.scrollIntoView({ 
        behavior,
        block: 'end',
        inline: 'nearest'
      });
    });
  }, []);

  // Optimized message fetching with abort controller
  // In Chat.js, modify fetchMessages:
const fetchMessages = useCallback(async () => {
  if (!channel?._id || !user?._id) {
    console.log('Missing channel or user ID for fetching messages');
    return;
  }

  // Cancel any ongoing requests
  if (abortControllerRef.current) {
    abortControllerRef.current.abort();
  }
  abortControllerRef.current = new AbortController();

  setLoadingMessages(true);
  setError(null);

  try {
    const response = await axiosInstance.get(
      `/api/message/channel/${channel._id}/messages`,
      { signal: abortControllerRef.current.signal }
    );
    
    if (!response?.data?.success) {
      throw new Error('Invalid response when fetching messages');
    }

    const fetchedMessages = response.data.messages || [];
    setMessages(fetchedMessages);

    // Add delay to ensure DOM update
    setTimeout(() => {
      requestAnimationFrame(() => {
        if (lastMessageRef.current) {
          lastMessageRef.current.scrollIntoView({ 
            behavior: 'auto',
            block: 'end'
          });
        }
      });
    }, 100);

  } catch (error) {
    // ... error handling
  } finally {
    setLoadingMessages(false);
  }
}, [channel?._id, user?._id]);

// Remove the scroll effect from ChatDisplay.js
// Delete or comment out this useEffect:
// useEffect(() => {
//   if (lastMessageRef.current) {
//     requestAnimationFrame(() => {
//       lastMessageRef.current?.scrollIntoView({ behavior: 'smooth' });
//     });
//   }
// }, [messages]);



  // Cleanup abort controller on unmount
  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, []);

  // Fetch messages when channel changes
  useEffect(() => {
    if (channel?._id && user?._id) {
      setMessages([]); // Clear messages before fetching new ones
      fetchMessages();
    }
  }, [channel?._id, user?._id, fetchMessages]);



  // Optimized new message handler
// In Chat component
const handleNewMessage = useCallback((newMessage) => {
  if (!newMessage?._id) {
    console.log('❌ No message ID found');
    return;
  }

  console.log('📝 Processing new message:', newMessage._id);

  setMessages(prevMessages => {
    if (prevMessages.some(msg => msg._id === newMessage._id)) {
      console.log('⚠️ Duplicate message detected:', newMessage._id);
      return prevMessages;
    }
    
    console.log('✅ Adding new message:', newMessage._id);
    const updatedMessages = [...prevMessages, newMessage];

    // Use MutationObserver to detect when the DOM is actually updated
    const observer = new MutationObserver(() => {
      console.log('🔄 DOM updated, attempting to scroll');
      if (lastMessageRef.current) {
        console.log('📜 Scrolling to new message:', newMessage._id);
        lastMessageRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'end'
        });
      }
      observer.disconnect();
    });

    // Start observing the chat container
    const chatContainer = document.querySelector('#chat-area');
    if (chatContainer) {
      observer.observe(chatContainer, {
        childList: true,
        subtree: true
      });
    }

    return updatedMessages;
  });
}, []);




  // Optimized delete message handler - no scrolling
// In Chat component
const handleDeletedMessage = useCallback((deletedMessageId) => {
  setMessages(prevMessages => 
    prevMessages.filter(msg => msg._id !== deletedMessageId)
  );
  // No scrollToBottom call here
}, []); // Empty dependency array since it doesn't use any external values


  if (!user) {
    return (
      <Box sx={{ padding: 2, textAlign: 'center' }}>
        <Typography>Please log in to view messages</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ 
      display: 'flex',
      flexDirection: 'column',
      height: '100dvh',
      maxHeight: '100dvh',
      position: 'relative',
      overflow: 'hidden'
    }}>
      <Box sx={{
        flex: 1,
        overflowY: 'auto',
        marginTop: '1px',
        marginBottom: '30px',
        padding: '5px',
        paddingBottom: isMobile ? '80px' : '70px',
        WebkitOverflowScrolling: 'touch'
      }}>
        <ChatDisplay
          messages={messages}
          channel={channel}
          lastMessageRef={lastMessageRef}
          onMessageDeleted={handleDeletedMessage}
          onNewMessage={handleNewMessage}
        />
      </Box>

      <Box sx={{
        position: 'fixed',
        bottom: 0,
        left: isSidebarOpen ? sidebarWidth : 0,
        right: 0,
        height: '70px',
        backgroundColor: 'background.paper',
        borderTop: '1px solid',
        borderColor: 'divider',
        transition: 'left 0.3s',
        zIndex: 1000,
        ...(isMobile && {
          paddingBottom: 'env(safe-area-inset-bottom)'
        })
      }}>
        <ChatInput
          user={user}
          currentChannel={channel}
          onMessageSent={handleNewMessage}
          isSidebarOpen={isSidebarOpen}
          sidebarWidth={sidebarWidth}
        />
      </Box>
    </Box>
  );
};

export default Chat;